import { Button, Slider, Tooltip, withStyles } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { isSessionRated, submitRating } from '../../containers/app/redux_actions'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'
import TriangleIcon from "./assets/triangle-scroll.svg"
import CircleIcon from "./assets/Circle.svg"
import CircleGreyIcon from "./assets/circle-grey.svg"
import { useSnackbar } from 'notistack'
import { Link } from 'react-router-dom'

const RatingSlider = withStyles({
    thumb: {
        color: '#FFBC36',
        marginLeft: 0,
        marginTop: -6
    },
    mark: {
        height: 5,
        width: 5,
        borderRadius: 50,
        marginTop: -1.5,
        color: '#C7C7C7'
    },
    markActive: {
        opacity: 1,
        backgroundColor: '#C7C7C7',
    },
    track: {
        opacity: 0
    },
    focusVisible: {
        boxShadow: null
    },
    valueLabel: {
        transform: "scale(1) translateY(60px)"
    }
})(Slider)

const Triangle = (props) => {
    const { children, value } = props;
    return (
        <>
            {children}
            <img src={TriangleIcon} style={{ marginTop: '5%', marginLeft: ((value - 1) * 25) - 9.5 + '%' }} />
        </>
    );
}

const Circle = (props) => {
    return <div {...props}>
        <img src={CircleIcon} />
    </div>
}

const GeryCircle = (props) => {
    return <div {...props}>
        <img src={CircleGreyIcon} />
    </div>
}

const CustomSlider = (props) => {
    const [click, setClick] = useState(false)

    return <div style={{ display: 'flex', marginTop: '15px' }}>
        <span className="tablet-gothic h-fit v-center w-25" style={{ color: "#575757" }}>{props.low}</span>
        <RatingSlider step={1}
            min={1}
            max={5}
            marks
            value={props.value}
            onClick={() => setClick(true)}
            onChange={(event, rating) => { props.onChange(event, rating); setClick(true) }}
            valueLabelDisplay={"on"}
            ValueLabelComponent={Triangle}
            ThumbComponent={click ? Circle : GeryCircle}
            className="w-50 ml-10 mr-10 mt-auto mb-auto"
        />
        <span className="tablet-gothic h-fit v-center w-25" style={{ color: "#575757" }}>{props.high}</span>
    </div>
}
export default () => {
    const { auth, app } = useSelector((state) => state)
    const [currentRating, setCurrentRating] = useState({})
    const [result, setResult] = useState({})
    const { moduleid } = useParams()
    // const { sessionId } = useParams()
    const session = app.attendedSessions[moduleid]
    const sessionId = session.sessionId
    const [done, setDone] = useState(false)
    const [error, setError] = useState(null)
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        isSessionRated({ user_token: auth.user_token, sessionId: sessionId, userId: auth.user_uid })
            .then((result) => result.json())
            // .then((result) => setDone(result))
            .then((result) => { if (result.code) setDone(true) })
    }, [])

    const submit = () => {
        if (Object.keys(currentRating).some((rate) => currentRating[rate] == '0'))
            setError("Please submit ratings for all the above")
        else {
            submitRating({ token: auth.user_token, sessionId: sessionId, rating: currentRating, userId: auth.user_uid }).then((result) => setResult(result))
            window.location.href = "/classroom"
        }
    }

    const questions = [{ question: "Did people participate in the class?", low: "The coach did most of the talking.", high: "The coach encouraged us to talk & listen to each other." },
    { question: "Was the class easy to follow?", description: "(Were exercises clearly introduced, smoothly conducted and well-summarized)", low: "I found it hard to follow.", high: "I found it easy to follow." },
    { question: "Was the session useful?", low: "I found it theoretical & not useful", high: "I gained something valuable to use at work" }]

    useEffect(() => {
        for (let que of questions) {
            setCurrentRating((oldRate) => ({ ...oldRate, [que.question]: 0 }))
        }
        enqueueSnackbar("Please slide the pointers");
    }, [])

    if (done)
        return <div>
            <div className="w-fit margin-auto">Session rating already submitted</div>
            <Link to={{ pathname: "/classroom" }} style={{ display: "flex", margin: 'auto', width: 'fit-content', marginTop: '20px', textDecoration: 'none' }}>
                <Button className="special-button">
                    Go to Home
                </Button>
            </Link>
        </div>
    else
        return <div className="session-rating">
            <div className="heading">Help your coach improve</div>
            {questions.map((que) =>
                <div className="rating-holder">
                    <div className="tablet-gothic fs-16 ls-22">{que.question}</div>
                    <div className="tablet-gothic fs-14 ls-18 fw-300">{que.description}</div>
                    <CustomSlider
                        text={que.text}
                        value={currentRating[que.question] || 0}
                        low={que.low}
                        high={que.high}
                        // defaultValue={1}
                        onChange={(event, rating) => setCurrentRating((oldRate) => ({ ...oldRate, [que.question]: rating }))} />
                </div>)}
            {error ? <div className="error" > {error} </div> : ""}
            <div className="flex">
                <Button className="special-button" style={{ width: '50%', margin: '20px auto 0px' }} onClick={submit}>
                    Submit
                </Button>
            </div>
        </div >
}