/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Redirect } from "react-router";

import LoginForm from "../../components/LoginForm";
// import ForgotPassword from "./ForgotPassword";

import { loginAttempt, setErrMsg, resetLoginPage } from "./redux_actions";

let Login = class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      showPassword: false,
      isDesktop: false,
      params: {}
    };
  }

  componentDidMount() {
    //reset all the values in the state;
    this.props.resetLoginPage();

    for (let param of window.location.search.replace("?", "").split("&&")) {
      let _param = param.split("=")
      this.state.params[_param[0]] = _param[1]
    }
  }

  render() {
    const { classes } = this.props;
    if (this.props.auth.isLoggedIn) {
      return <Redirect
        to={{
          pathname: "/",
          search: window.location.search
        }}
      />
    } else {
      return <LoginForm
        loginAttempt={this.props.loginAttempt}
        authMsg={this.props.auth.loginErrorMsg}
        token={this.state.params?.token}
      />
    }
  }
};

const mapStateToProps = (state) => ({
  notification: state.notification,
  auth: state.auth,
});

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      loginAttempt,
      setErrMsg,
      //requestPermissionToNotify,
      resetLoginPage,
    },
    dispatch
  );
}

export default connect(mapStateToProps, matchDispatchToProps)(Login);
