import React, { useEffect, useState } from "react";
import { LockOutlined as LockOutlinedIcon } from '@material-ui/icons';
import './style.css'
import { useSelector } from "react-redux";
import StarMakerIcon from "./assets/starmakeracad.svg"
import { getDashboard, firstTimeUser, setFirstTimeUse } from "./redux_actions";
import { Link } from "react-router-dom";
import Walkthrough from "../../components/Walkthrough";
import store from "../../Redux_Store";

const Resources = (props) => {
  const { auth, app } = useSelector(state => state)
  const [score, setScore] = useState({})
  // const [showWalkthrough, setShowWalkthrough] = useState(false);

  useEffect(() => {
    document.body.style.backgroundColor = "#FFF";
    Object.keys(app?.showWalkthrough)?.length === 0 && firstTimeUser(auth.user_token, auth.userAccount.id).then((res) => {
      store.dispatch({ type: "SHOW_WALKTHROUGH", payload: res })
    })
    getDashboard(auth.user_token, auth.user_uid).then((res) => {
      setScore(res)
    })
  }, [auth.user_uid])

  const cardData = [
    { title: "classroom learning", description: "Starmaker Coaching Program", text: "view your sessions", locked: false, link: "/classroom" },
    { title: "daily learning", description: "10-minute Flashpoint Gym", locked: false, link: "/flashpoints", text: "Begin my gym" }
  ]

  const walkthroughImages = [
    'https://s3.ap-south-1.amazonaws.com/myapp.resources/FlashPoints/Walkthrough/Start+1.svg',
    'https://s3.ap-south-1.amazonaws.com/myapp.resources/FlashPoints/Walkthrough/Start+2.svg'
  ];

  if (!app?.showWalkthrough?.appOpened)
    return <Walkthrough
      walkthroughImages={walkthroughImages}
      buttonStyle={{ position: "absolute", bottom: "8%", left: `${(walkthroughImages.length - 1) * 100 + 14}%`, width: "70%" }}
      buttonClasses={'walkthrough-button'}
      buttonText="Enter the App"
      buttonAction={() => {
        // setShowWalkthrough(false);
        store.dispatch({
          type: "SHOW_WALKTHROUGH", payload: {
            'appOpened': true
          }
        })
        setFirstTimeUse(auth.user_token, auth.userAccount.id, { appOpened: true })
      }}
    />
  else
    return <div style={{ margin: '37px 16px' }} className='overFlowYAuto textbox-scrollable-darkgray'>
      <div style={{ display: 'inline-block', width: '100%' }}>
        <div style={{ color: '#FFBC36', font: 'normal normal 800 28px/38px tablet-gothic' }}>myApp</div>
        <div style={{ font: 'normal normal 900 32px/34px Source Serif Pro', letterSpacing: '0.32px' }}>Starmaker Associate Academy</div>
        <img src={StarMakerIcon} style={{ width: '150px', float: 'right' }} />
      </div>
      <div>
        {cardData.map(card => <Link className="card" to={card?.link}>
          <div>
            {card?.title == "daily learning" ? <div className="blinking" >New Launch!</div> : ""}
            <div className="card-title">{card.title}</div>
            <div className="card-description">{card.description}</div>
            <div className="card-link">{card?.text} </div>
          </div>
          <div className="card-left-right">
            {card.locked ? <><LockOutlinedIcon /> <div style={{ font: 'normal normal 300 12px/14px tablet-gothic', color: '#6E6E6E' }}>Coming Soon</div></> : <>
              <div className="card-score-text">Score</div>
              <div className="card-total-score"><span className="card-score">{score[card?.title]?.score}</span>/{score[card?.title]?.total}</div>
            </>}
          </div>
        </Link>
        )}
        <div className="card" style={{ background: '#F2F2F2 0% 0% no-repeat padding-box' }}>
          <div>
            <div className="card-title">total score</div>
          </div>
          <div className="card-left-right">
            <div className="card-total-score"><span className="card-score">{Object.values(score)?.reduce((total, item) => total + item.score, 0)}</span>/{Object.values(score)?.reduce((total, item) => total + item.total, 0)}</div>
          </div>
        </div>
      </div>
    </div >
}


export default Resources;