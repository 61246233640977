import React from 'react';
import Header from "../header";
import './style.css';

import { useTheme } from '@material-ui/core/styles';

import SwipeableViews from 'react-swipeable-views';

import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';

import TextEditor from '../../../../Tools/TextEditor/TextEditor';

import { addFlashpointData, getFlashpointData, manageToolData } from '../../redux_actions'


export default ({ app, auth, history, next }) => {

    const { user_token, user_uid, userAccount } = auth;
    const { engagementData } = app;
    let unitId = app?.fpSelectedUnit?._id;
    const questions = app?.fpSelectedUnit?.content?.[0]?.questions;

    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);

    const letterCount = (Child) => {
        let count = engagementData[questions?.[Child]?.key] ? engagementData[questions?.[Child]?.key]?.blocks?.reduce((prevValue, item) => {
            return prevValue?.text?.length ? prevValue?.text?.length + item?.text?.length : typeof prevValue === 'object' ? item?.text?.length : prevValue + item?.text?.length;
        }) : 0;
        // console.log(count, typeof count);
        if (typeof count === 'object') {
            if (count?.text?.length)
                return 500 - count.text.length;
            return 500;
        }
        else if (typeof count === 'string') return 500;
        return 500 - count;
    }

    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    const handleNext = () => {

        if (letterCount(activeStep) > 400)
            alert('Think more! Add atleast 100 characters to create a meaningful story.');
        else {
            console.log(engagementData);
            addFlashpointData(user_token, unitId, user_uid, engagementData);
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };


    const moderator = userAccount?.role?.find(ele => ele.rolename === "Flashpoint Response Moderator");

    return <div className='w-100' style={{ backgroundColor: app?.fpSelectedUnit?.color }}>
        <SwipeableViews
            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            index={activeStep}
            // onChangeIndex={handleStepChange}
            // enableMouseEvents
            disabled={true}
        // className='h-fit'
        >
            {Object.keys(questions).map((Child, index) => (
                <div style={{ overflow: 'hidden' }} key={index + ' child'}>
                    {Math.abs(activeStep - index) <= 2 ? (
                        // <img src={step.imgPath} alt={step.label} />
                        <>
                            <Header history={history} showFlashpoint={false} showDescription={false} setShowDescription={false} unit={app?.fpSelectedUnit} alternateResponse={true} submit={false} title={questions?.[Child]?.title} questionsBack={index && handleBack} />
                            <div className='fp-points-description' style={{ padding: '3%', margin: '5% 0%', height: '60vh' }}>
                                <div className='fp-success-story-textBox'>
                                    <TextEditor
                                        id={questions?.[Child]?.key} initialData={engagementData[questions?.[Child]?.key] || null}
                                        toolbarClassName='rdw-editor-toolbar-custom'
                                        placeholder='Fill here...'
                                        scrollable='textbox-scrollable-darkgray'
                                        textEditorClass='UPTC_CS_Square-textEditorClass'
                                        wrapperClass='text-editor-WIAC_diff_in_the_lives'
                                        toolBarClass='CD-CNP-textbox-toolbar'
                                    />
                                    <div style={{ textAlign: 'right', color: '#989595' }}>
                                        {letterCount(Child)}
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : null}
                </div>
            ))}
        </SwipeableViews>

        {
            activeStep === questions.length - 1 ?
                <Link className='text-decoration-none' onClick={(event) => {
                    if (letterCount(activeStep) > 400) {
                        event.preventDefault();
                        alert('Think more! Add atleast 100 characters to create a meaningful story.');
                    }
                    else
                        addFlashpointData(user_token, unitId, user_uid, engagementData);
                }} to={{
                    pathname: `/flashpoints/unit/${unitId}/submit`, state: {
                        title: 'Thank you for Sharing!',
                        description: 'Story you shared has been added to your StarMaker Profile!',
                        subDescription: 'You will be able to see your profile soon!'
                    }
                }}>
                    <Button className='fp-alternative-response-button' style={{ backgroundColor: 'black', color: 'white' }} onClick={next}>Submit your Story!</Button>
                </Link> : <Button className="fp-unit-button" onClick={handleNext} disabled={activeStep === questions.length - 1}>
                    <span style={{ width: '80%', position: 'relative' }}>

                        Go to Part {activeStep + 2} of your Story
                    </span>
                    <span>&nbsp; &nbsp; &gt;</span>
                </Button>
        }
    </div >
}