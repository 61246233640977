import React, { useState } from "react"
import { Button } from "@material-ui/core"
import { ChevronLeft } from "@material-ui/icons"
import AlternativeResponses from "./alternativeResponses"
import FP_Icon from './assets/Flashpoint_Icon.svg'
import Points from './points.js'
import ScrollDownButton from './assets/Scroll down 3.svg'

import CloseDialog from "./CloseDialog"
import Header from './header.js'

import SuccessStoryPoints from './Success_Stories/Points';

// export default ({ flashPointRootRef, unit, alternateResponse, showFlashpoint, submit, showCloseDialog, setClose, scrollBottom, alternateResponseData, unitId, close }) => {
export default ({ app, auth, history }) => {

    const [showDescription, setShowDescription] = useState(-1);

    const getFlashpointContent = (content) => {
        if (content?.type == "img")
            return <img className='d-block mx-auto h-100' src={app?.fpSelectedUnit?.content[0]?.src} />
        // else if (content?.type == "checklist")
        //     return <CheckList data={content} content={content?.items} />
        // else if (content?.type == "quiz")
        //     return <Quiz content={content?.question} />
        else if (content?.type == "feedback")
            return <AlternativeResponses />
        else if (content?.type == "points")
            // return <Points content={content} next={() => {
            //     window.location.search += "&response=true"
            // }} alternateResponseDataLength={alternateResponseData?.length}
            //     showDescription={showDescription}
            //     setShowDescription={setShowDescription} />
            return <Points content={content}
                unitId={app?.fpSelectedUnit?._id}
                responseData={app?.alternateResponse}
                app={app}
                // alternateResponseDataLength={app?.alternateResponse?.length}
                alternateResponseDataLength={app?.fpSelectedUnit?.numResponses}
                alternateResponseLoading={app?.fpAlternateResponseLoading}
                showDescription={showDescription}
                setShowDescription={setShowDescription} />

        else if (content?.type == "SuccessStoryPoints")

            return <SuccessStoryPoints content={content}
                unitId={app?.fpSelectedUnit?._id}
                responseData={app?.alternateResponse}
                app={app}
                auth={auth}
                showDescription={showDescription}
                setShowDescription={setShowDescription} />
        else if (content?.type == "AlternateResponse")
            return <AlternativeResponses />
    }

    return <div className='w-100' style={{ backgroundColor: app?.fpSelectedUnit?.color }}>
        <Header history={history} showFlashpoint={true} showDescription={showDescription} setShowDescription={setShowDescription} unit={app?.fpSelectedUnit} alternateResponse={false} submit={false} />
        <div style={{ padding: "1%", minHeight: '75vh' }}>
            {/* {alternateResponse ? <AlternativeResponses
                unitId={unitId}
                next={() => {
                    window.location.search += "&submit=true"
                }} responseData={alternateResponseData} /> : getFlashpointContent(unit?.content[0])} */}

            {getFlashpointContent(app?.fpSelectedUnit?.content[0])}
        </div>

    </div>
}