import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Input,
  InputLabel,
  FormControl,
  Button,
  Typography,
  CircularProgress,
  TextField
} from "@material-ui/core";
import { Link } from "react-router-dom";

import { resetPassword, resetForgotPasswordPage } from "./redux_actions";

let ForgotPassword = class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      firstPaint: true
    };



    this.handleChange = prop => event => {
      this.setState({ [prop]: event.target.value });
    };

    this.toLoginButton = _props => (
      <Link to='/login' className='text-decoration-none'>
        <Button
          fullWidth
          className="login-button"
          component={this.toLogin}
        >
          Login
        </Button>
      </Link>
    );

    this.RegisterButton = _props => (
      <Button
        fullWidth
        className="login-button"
        onClick={() => {
          props.resetPassword({ email: this.state.email, site_url: window.location.origin });
        }}
      >
        Reset Password
      </Button>
    );
  }

  componentWillMount() {
    let { firstPaint } = this.state;
    if (firstPaint) {
      this.props.resetForgotPasswordPage();
      this.setState({ firstPaint: false });
    }
  }

  render() {
    let { auth, classes } = this.props;
    let { resetPasswordInProgress, passwordLinkSent } = auth;

    return (
      <div className="auth-form-cntr">
        <div className="forgot-pw-cntr">
          <div>
            <div style={{ color: '#FFBC36', font: 'normal normal 800 28px/38px tablet-gothic', margin: '10% 0%' }}>myApp</div>

            {passwordLinkSent
              ? `We just sent an email to ${this.state.email}`
              : "Forgot your password?"}

          </div>
          <div className="forgot-pw-text-msg">
            <Typography gutterBottom>
              {passwordLinkSent
                ? "Click the secure link we sent you to reset your password. If you didn't receive an email, check your Spam Folder or try again and make sure you enter the email address associated with channel illumine account."
                : "Enter your email address below and we'll send you a secure link to reset your password"}
            </Typography>
          </div>
        </div>
        {passwordLinkSent ? null : (
          <div className="ap-login-field-container mb-15">
            <TextField
              variant="filled"
              label="Email"
              fullWidth
              type="text"
              autoComplete="on"
              InputProps={{ style: { backgroundColor: "#FFFFFF" }, disableUnderline: true }}
              value={this.state.email}
              onChange={this.handleChange("email")} />
          </div>

        )}
        <div>
          <Typography
            className="errorMsg"
            variant="caption"
            gutterBottom
            align="center"
            color="secondary"
          >
            {this.props.auth.forgotPasswordErrorMsg}
          </Typography>
        </div>
        <div>
          {resetPasswordInProgress ? (
            <CircularProgress size={32} />
          ) : (
            <FormControl fullWidth className="input-cntr">
              {passwordLinkSent ? this.toLoginButton() : this.RegisterButton()}
            </FormControl>
          )}
        </div>
        <div className="forgot-password-bottom" >
          {!passwordLinkSent && <Button component={Link} to="/auth/login" style={{ marginLeft: '5px', textDecoration: 'underline', font: 'normal normal bold 18px/20px tablet-gothic', color: '#BA1F00' }} >
            Login
          </Button>}
          <div className="mt-15" style={{ textAlign: 'center' }}>
            New?
            <Link
              to={{ pathname: "/auth/register", search: window.location.search }}
              style={{ marginLeft: '5px', textDecoration: 'underline', font: 'normal normal bold 18px/20px tablet-gothic', color: '#BA1F00' }}>
              Register
            </Link>
          </div>
        </div>
      </div>
    );
  }
};
const mapStateToProps = state => ({
  auth: state.auth
});

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      resetPassword,
      resetForgotPasswordPage
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(ForgotPassword);
