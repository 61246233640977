import React, { useEffect, useState } from "react";
import { Button, CircularProgress } from "@material-ui/core";
import { ThumbDown, ThumbDownOutlined, ThumbUp, ThumbUpOutlined } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { voteResponse, completeFlashpoint } from "../redux_actions";

import { WhatsappShareButton, WhatsappIcon } from "react-share";
import { Share } from '@material-ui/icons';
import store from "../../../Redux_Store";

export default ({ app, auth, ...props }) => {

    const [fpVote, setFpVote] = useState('');
    const { user_token, user_uid } = auth;
    const score = app?.fpScore;
    const unitId = app?.fpSelectedUnit?._id;

    let linkValues = props?.history?.location?.state;

    console.log(props, linkValues);

    useEffect(() => {
        store.dispatch({ type: "FP_SCORE_LOADING" })
        completeFlashpoint(user_token, unitId, user_uid, "Completed", false).then((res) => {
            store.dispatch({ type: "FP_SCORE", payload: res })
        })
        return () => {

        }
    }, [])

    return <div className='h-100 text-center textbox-scrollable-darkgray' style={{ backgroundColor: '#FFF8EC', overflowY: 'auto' }}>
        <div className='font-weight-bold fp-unit-completed large-text'>
            {score?.alreadyCompleted ? <>You already completed <br />the flashpoint!</> : linkValues.title}
        </div>
        <div className='fp-score'>
            <div style={{ fontSize: '4rem' }}>{app?.fpScoreLoading ? <CircularProgress /> : score?.score && Object.values(score?.score).reduce((total, item) => total + item, 0)}</div>
            <div style={{ fontSize: '0.8rem' }}>Points</div>
        </div>
        {!app?.fpScoreLoading && !linkValues.description && <div className='font-style-italic'>
            {
                score?.score && Object.entries(score?.score).map(s => {
                    return <div key={score[0]} className='small-text'> {`${s[0]}-${s[1]}`} Points </div>
                })
            }
        </div>}

        {linkValues.description ? <div>
            <div className='font-weight-bold fp-unit-completed large-text'>{linkValues.description}</div>
            <div>{linkValues.subDescription}</div>
        </div> : <div className='fp-score-unit-vote'>
            <div>Was this useful?</div>
            <div style={{ marginTop: '5%' }}>
                {
                    fpVote === '' && <><ThumbUpOutlined style={{ margin: '0% 7%' }} onClick={() => { voteResponse(user_token, unitId, undefined, user_uid, 1); setFpVote('up') }} />
                        <ThumbDownOutlined onClick={() => { voteResponse(user_token, unitId, undefined, user_uid, -1); setFpVote('down') }} /></>
                }
                {
                    fpVote === 'up' && <><ThumbUp style={{ margin: '0% 7%' }} />
                        <ThumbDownOutlined onClick={() => { voteResponse(user_token, unitId, undefined, user_uid, -1); setFpVote('down') }} /></>

                }
                {
                    fpVote === 'down' && <><ThumbUpOutlined style={{ margin: '0% 7%' }} onClick={() => { voteResponse(user_token, unitId, undefined, user_uid, 1); setFpVote('up') }} />
                        <ThumbDown /></>
                }
            </div>
        </div>}

        <div className='fp-score-dashboard-share-container'>
            <Link className='text-decoration-none' to='/flashpoints'>
                <Button className="fp-unit-button">
                    <span className='text-center' style={{ width: '75%' }}>Go to Dashboard </span>
                    <span className='text-center' style={{ width: '25%' }}>&nbsp; &nbsp; &gt;</span>
                </Button>
            </Link>
            <WhatsappShareButton className='fp-score-share-button' title='Hey! Checkout this flashpoint' separator=':- ' url={'https://' + window.location.host + '/flashpoints/unit/' + unitId}>
                <span style={{ width: '85%' }}>Share with other associates </span>
                <span className='share'>&nbsp; &nbsp; <Share /></span>
            </WhatsappShareButton>
        </div>

        {/* <Link to='/flashpoints/dashboard'>
            <Button className="fp-card-view-fp-button">Go to Dashboard </Button>
        </Link> */}
    </div>
}