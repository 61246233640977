import React from 'react';
import { Link } from "react-router-dom"
import {
    MoreVert as MoreVertIcon,
    Home as HomeIcon
} from "@material-ui/icons"
import { Menu, MenuItem } from "@material-ui/core"
import { useState } from "react"
import store from "../../Redux_Store"
import { signOut } from "../../containers/auth/redux_actions"

export default ({ name, leftComponent }) => {
    const [menuOpen, setMenuOpen] = useState(false)

    const menus = [
        { name: "Logout", onClick: () => { store.dispatch(signOut()) } },
        { name: "Privacy Policy", onClick: () => window.open("https://illumine.in/privacy-policy.html", "_blank") },
        { name: "Cookie Policy", onClick: () => window.open("https://illumine.in/cookie-policy.html", "_blank") },
        { name: "Terms & Conditions", onClick: () => window.open("https://illumine.in/terms-of-use.html", "_blank") }
    ]

    return <>
        {leftComponent}
        <div style={{ float: 'right', display: "inline" }}>
            <Link to={"/"} style={{ color: "black", textDecoration: 'none' }}> <HomeIcon className="pointer" style={{ fontSize: "2rem" }} /> </Link>
            <MoreVertIcon onClick={(event) => setMenuOpen(event.currentTarget)} aria-haspopup="true" className="pointer" />
            <Menu
                open={Boolean(menuOpen)}
                onClose={() => setMenuOpen(null)}
                anchorEl={menuOpen}
            >
                {menus.map((menu) => <MenuItem key={menu.name} onClick={menu.onClick}> {menu.name} </MenuItem>)}
            </Menu>
        </div>
    </>
}