import React from "react";
import PropTypes from "prop-types";
import {
  IconButton,
  InputLabel,
  InputAdornment,
  FormControl,
  Button,
  TextField,
  FilledInput,
  FormHelperText,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { Visibility, VisibilityOff } from "@material-ui/icons";

// import { sideNavData } from "./tileData";
// import ForgotPassword from "../../containers/ForgotPassword";
import "./Registration.css";

const styles = {};

class Registration extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      showPassword: false,
      accesscode: this.props['accesscode'],
      fullname: "",
      label: ""
    };

    this.handleChange = (prop) => (event) => {
      if (prop == "accesscode")
        this.setState({ [prop]: event.target.value.toUpperCase() });
      else
        this.setState({ [prop]: event.target.value });
    };

    this.handleMouseDownPassword = (event) => {
      event.preventDefault();
    };

    this.handleClickShowPassword = () => {
      this.setState({ showPassword: !this.state.showPassword });
    };
  }

  register = () => {
    const { accesscode, email, password, fullname } = this.state

    this.props.registerAttempt({
      email,
      password,
      accesscode,
      fullname,
      successCallBack: (user) => {
        // this.props.subscibe(user.studentid)
        this.props.loginAttempt({ email, password, token: this.props.params.token })
      },
      failureCallback: () => {
        //alert('failed');
      }
    });
  }

  render() {
    const { classes } = this.props;

    return (<div style={{ margin: '60px 30px' }}>
      <div style={{ color: '#FFBC36', font: 'normal normal 800 28px/38px tablet-gothic' }}>myApp</div>
      <div className="mb-15" style={{ font: 'normal normal 900 32px/34px Source Serif Pro', letterSpacing: '0.32px' }}>Starmaker Associate Academy</div>
      {this.state.label ?
        (<div className="errorMsg">
          <label> {this.state.label} </label>
        </div>) : null
      }
      <div className="ap-login-field-container mb-15">
        <TextField
          variant="filled"
          label="Email"
          type="email"
          autoComplete="off"
          fullWidth
          InputProps={{ style: { backgroundColor: "#FFFFFF" }, disableUnderline: true }}
          value={this.state.email}
          onChange={this.handleChange("email")}
        />
      </div>

      <div className="ap-login-field-container mb-15">
        <FormControl fullWidth className={"input-cntr"} variant="filled">
          <InputLabel htmlFor="adornment-password">Password</InputLabel>
          <FilledInput
            // id="user-password"
            label="Password"
            autoComplete="off"
            disableUnderline
            inputProps={{ style: { backgroundColor: "#FFFFFF" } }}
            type={this.state.showPassword ? "text" : "password"}
            value={this.state.password}
            onChange={this.handleChange("password")}
            endAdornment={
              <InputAdornment position="end"
                style={{ backgroundColor: "#FFFFFF" }}
              >
                <IconButton
                  aria-label="Toggle password visibility"
                  onClick={this.handleClickShowPassword}
                  onMouseDown={this.handleMouseDownPassword}
                >
                  {this.state.showPassword ? (
                    <VisibilityOff />
                  ) : (
                    <Visibility />
                  )}
                </IconButton>
              </InputAdornment>
            }
          />
          <FormHelperText style={{ fontSize: '10px' }}>Minimum 8 characters (Atleast 1 letter & 1 numeric)</FormHelperText>
        </FormControl>
      </div>

      <div className="ap-login-field-container mb-15" hidden={"accesscode" in this.props.params}>
        <TextField
          variant="filled"
          label="Access Code"
          fullWidth
          InputProps={{ style: { backgroundColor: "#FFFFFF" }, disableUnderline: true }}
          type="text"
          autoComplete="off"
          value={this.state.accesscode}
          onChange={this.handleChange("accesscode")}
        />
      </div>

      <div className="ap-login-field-container mb-15">
        <TextField
          variant="filled"
          fullWidth
          label="Full Name"
          autoComplete="off"
          InputProps={{ style: { backgroundColor: "#FFFFFF" }, disableUnderline: true }}
          type="text"
          value={this.state.fullname}
          onChange={this.handleChange("fullname")}
        />
      </div>

      {this.props.authMsg ? <div className="errorMsg">{this.props.authMsg}</div> : ""}

      <Button
        fullWidth
        className="login-button"
        onClick={() => this.register()}
      >
        Register
      </Button>
      <div className="mt-15" style={{ textAlign: 'center' }}>
        <span>Already have an account?</span>
        <Link
          to={{ pathname: "/auth/login", search: window.location.search }}
          style={{ color: "#e6111b", textDecoration: "none" }}
          className="ml-5"
        >
          Login
        </Link>
      </div>
      <div style={{ marginTop: '20px', textAlign: 'center', font: 'normal normal normal 14px/26px tablet-gothic', letterSpacing: '0.28px', color: '#9B9B9B' }}> Copyright 2019-21; Illumine. All rights reserved.</div>
    </div >)
  }
}

Registration.propTypes = {
  classes: PropTypes.object.isRequired,
  registerAttempt: PropTypes.func,
  authMsg: PropTypes.string,
};

export default withStyles(styles)(Registration);
