import React from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
});

export default function RadioButtonsGroup({ content }) {
    const [value, setValue] = React.useState({});

    const [activeStep, setActiveStep] = React.useState(0);

    const handleChange = (event, key) => {

        let temp = {
            [key]: event.target.value
        }
        if (Object.keys(value).length === 0)
            setValue({
                ...temp
            });
        else
            setValue({
                ...value,
                ...temp
            });

    };

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };    

    const normalise = value => (value + 1) / content?.length * 100;

    return (
        <div className='fp-unit-quiz-container'>
            <div className='font-tablet-gothic font-weight-bold'>Quiz {activeStep + 1}/{content.length}</div>
            <div style={{ margin: '5% 0%' }}>{content[activeStep].question}</div>
            <LinearProgress variant="determinate" value={normalise(activeStep)} />
            <FormControl component="fieldset" className='fp-unit-quiz-ans-container'>
                <div className='font-tablet-gothic font-weight-bold'>{content[activeStep].subQuestion}</div>
                <FormLabel component="legend"></FormLabel>
                <RadioGroup value={value && content && content[activeStep] && value[content[activeStep]] && value[content[activeStep]?.question] || ''} onChange={(event) => handleChange(event, content[activeStep].question)}>
                    {
                        content[activeStep].options.map((ans) => {
                            return <FormControlLabel key={ans.option} checked={value[content[activeStep]?.question] === ans.option} value={ans.option || ''} control={<Radio />} label={ans.option} />
                        })
                    }
                </RadioGroup>
            </FormControl>
            <div className='d-flex justify-content-space-evenly'>
                <Button size="small" className='fp-quiz-back-button' style={{ backgroundColor: "darkgray !important" }} onClick={handleBack} disabled={activeStep === 0}>
                    Back
                </Button>
                <Button size="small" className='fp-view-response-button' disabled={activeStep === content.length - 1} onClick={handleNext}>
                    Next &#9654;
                </Button>
            </div>
            {/* <MobileStepper
                steps={content?.length}
                position="static"
                variant="text"
                // style={{ height: '10%', boxShadow: '0px -2px 4px -2px #c9c9c9', margin: '0% -2%' }}
                activeStep={activeStep}
                nextButton={

                }
                backButton={

                }
            /> */}

        </div >
    );
}
