import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Button } from '@material-ui/core';
import AddBestPractice from './addBestPractice';

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
});

export default function ActionsInAccordionSummary({ data, content }) {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <div className='font-tablet-gothic font-weight-bold'>{data.title}</div>
            <div className='font-tablet-gothic'> {data.description}</div>
            {
                content.map(item => <Accordion key={item.title}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-label="Expand"
                        aria-controls="additional-actions1-content"
                        id="additional-actions1-header"
                    >
                        <FormControlLabel
                            className='tablet-gothic'
                            onClick={(event) => event.stopPropagation()}
                            onFocus={(event) => event.stopPropagation()}
                            control={<Checkbox />}
                            label={item.title}
                        />
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography color="textSecondary">
                            {item.description.map(text => <div key={text} className='tablet-gothic'>{text}</div>)}
                        </Typography>
                    </AccordionDetails>
                </Accordion>)
            }
            <div className='font-tablet-gothic'>{data.info}</div>
            <AddBestPractice />
        </div>
    );
}
