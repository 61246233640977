import { Button, Dialog, makeStyles, MobileStepper, Slider, useTheme } from "@material-ui/core";
import { ArrowRightAltOutlined, KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";
import React, { useEffect, useRef, useState } from "react"
import { isMobile } from "react-device-detect";
import SwipeableViews from "react-swipeable-views";
import { getTakeawayResponse, sendTakeawayResponse } from "../../containers/app/redux_actions";
import Slide1 from "./Slide1.JPG"
import Slide2 from "./Slide2.JPG"
import Slide3 from "./Slide3.JPG"
import "./style.css"
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
    rail: {
        backgroundColor: '#FFFF00',
        height: 5,
        opacity: 1
    },
    verticalRail: {
        '& $rail': {
            width: 5
        },
    },
    thumb: {
        backgroundColor: "#376092",
        border: '2px solid white',
        width: 17,
        height: 17
    }
}));

export default (props) => {
    const [activeStep, setActiveStep] = React.useState(0);
    const theme = useTheme();
    const classes = useStyles();
    const [values, setValues] = useState({
        habit_1: 0,
        habit_2: 100,
        habit_3: 100,
        habit_4: 0,
        habit_5: 0,
        habit_6: 0,
        notes: ""
    })

    const { enqueueSnackbar } = useSnackbar();

    // Width and Height
    const [open, setOpen] = useState(true)
    const [width, setWidth] = useState(null);
    const [height, setHeight] = useState(null);
    let docRatio = 0.63333; //default is potrait
    // let docRatio = 2.2; //default is potrait
    var w = window.innerWidth;
    var h
    if (isMobile)
        h = window.innerHeight - 100;
    else
        h = window.innerHeight - 50;
    var deviceRatio = w / h;

    useEffect(() => {
        console.log(width, height)
        if (w / h >= docRatio) {
            // This need to be fixed to the height
            setWidth(h * docRatio);
            setHeight(h);
        } else {
            //This need to be fixed to width
            setWidth(w);
            setHeight(w / docRatio + 10);
        }
    }, [window]);


    const handleChange = (prop, newValue) => {
        setValues({ ...values, [prop]: newValue })
    };

    const onClickBack = () => {
        sendTakeawayResponse({ user_token: props.user_token, takeawayId: props.takeawayId, studentId: props.uid, response: values })
        props.onBack();
        setOpen(false)
        enqueueSnackbar("Your notes are saved. You can come back and refer anytime.");
    }

    useEffect(() => {
        getTakeawayResponse({ user_token: props.user_token, takeawayId: props.takeawayId, studentId: props.uid })
            .then((result) => {
                if (result?.data && result?.data.length > 0)
                    setValues(result.data[0]['response'])
            })
    }, [])

    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    let maxSteps = 3

    return <>
        <Dialog open={true} onClose={onClickBack} style={{ margin: 'auto' }} scroll="none" fullScreen>
            {/* <Dialog open={true} onClose={onClickBack} style={{ margin: 'auto' }} scroll="none" fullScreen={isMobile ? true : false}> */}
            {/* <div onClick={onClickBack} style={{ position: 'fixed', zIndex: 1, backgroundColor: 'red', padding: '5px', right: w / 2 - width / 2, top: h / 2 - height / 2, marginRight: '5px', marginTop: '5px' }}>Close</div> */}
            {/* <div style={isMobile ? { display: "flex", width, marginTop: height / 2, zIndex: 1, position: 'fixed' } : { display: "flex", width, top: height / 2, zIndex: 1, position: 'fixed', left: (w - width) / 2 }}>
                <Button onClick={handleBack} disabled={activeStep === 0} style={{ border: 'none', padding: '9px 2px', borderRadius: '0px' }}>
                    <KeyboardArrowLeft style={{ color: 'white', width: 36, height: 36 }} viewBox="0 0 18 18" />
                </Button>
                <Button style={{ marginLeft: 'auto', border: 'none', padding: '9px 2px', borderRadius: '0px' }} onClick={handleNext} disabled={activeStep === maxSteps - 1}>
                    <KeyboardArrowRight style={{ color: 'white', width: 36, height: 36 }} viewBox="0 0 18 18" />
                </Button>
            </div> */}
            <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={activeStep}
                // onChangeIndex={handleStepChange}
                // enableMouseEvents
                style={{ marginTop: 'auto', marginBottom: 'auto' }}
                className="takeaway-swipe"
                disabled={true}
            >
                <img src={Slide1} style={{ width, height }} />
                <div style={{ position: 'relative' }} >
                    <img style={{ width, height }} src={Slide2} />
                    <div className='myapp-slider-1'>
                        <Slider
                            track={false}
                            value={values.habit_1}
                            classes={{ rail: classes.rail, thumb: classes.thumb }}
                            onChange={(event, value) => handleChange('habit_1', value)}
                            aria-labelledby="continuous-slider1" />
                    </div>
                    <div className='myapp-slider-2' >
                        <Slider
                            track={false}
                            value={values.habit_2}
                            classes={{ rail: classes.rail, thumb: classes.thumb, vertical: classes.verticalRail }}
                            onChange={(event, value) => handleChange('habit_2', value)}
                            aria-labelledby="continuous-slider2"
                            orientation="vertical" />
                    </div>
                    <div className='myapp-slider-3' >
                        <Slider
                            track={false}
                            value={values.habit_3}
                            classes={{ rail: classes.rail, thumb: classes.thumb }}
                            onChange={(event, value) => handleChange('habit_3', value)}
                            aria-labelledby="continuous-slider3" />
                    </div>
                    <div className='myapp-slider-4' >
                        <Slider
                            track={false}
                            value={values.habit_4}
                            classes={{ rail: classes.rail, thumb: classes.thumb, vertical: classes.verticalRail }}
                            onChange={(event, value) => handleChange('habit_4', value)}
                            aria-labelledby="continuous-slider4"
                            orientation="vertical" />
                    </div>
                    <div className='myapp-slider-5' >
                        <Slider
                            track={false}
                            value={values.habit_5}
                            classes={{ rail: classes.rail, thumb: classes.thumb, vertical: classes.verticalRail }}
                            onChange={(event, value) => handleChange('habit_5', value)}
                            aria-labelledby="continuous-slider5"
                            orientation="vertical" />
                    </div>
                    <div className='myapp-slider-6' >
                        <Slider
                            track={false}
                            value={values.habit_6}
                            classes={{ rail: classes.rail, thumb: classes.thumb }}
                            onChange={(event, value) => handleChange('habit_6', value)}
                            aria-labelledby="continuous-slider6" />
                    </div>
                </div>
                <div style={{ backgroundImage: `url(${Slide3})`, backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', position: 'relative', width, height }}>
                    <textarea
                        style={{ top: '11%', left: '8%', width: '84%', height: "71%", borderRadius: '8%', position: 'absolute', padding: '5%' }}
                        placeholder="Fill here..."
                        value={values.notes}
                        onChange={(event) => handleChange('notes', event.currentTarget.value)}
                    />
                </div>
            </SwipeableViews>
            <MobileStepper steps={3}
                variant="text"
                activeStep={activeStep}
                backButton={
                    <Button onClick={handleBack} disabled={activeStep === 0}>
                        <KeyboardArrowLeft /> Back
                    </Button>}
                nextButton={
                    activeStep === maxSteps - 1 ? <Button onClick={onClickBack}>
                        DOne
                    </Button> : <Button onClick={handleNext}>
                        Next <KeyboardArrowRight />
                    </Button>
                }
                style={{ width, marginLeft: 'auto', marginRight: 'auto' }}
            />
        </Dialog>
    </>
}