import React, { useEffect } from "react";
import { Switch, Route, Link } from "react-router-dom";

import Login from "./Login";
import Register from "./Register";
import ForgotPassword from "./ForgotPassword";
import "./auth.css";
import { Button, CircularProgress } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useLocation } from "react-router";
import ResetPassword from "./ResetPassword";
import { loginWithToken } from "./redux_actions";

let Auth = () => {
  const { auth } = useSelector(state => state)
  const location = useLocation()
  const search = location.search;
  const searchParams = new URLSearchParams(search)
  const studentId = searchParams.get('studentId');
  const token = searchParams.get("token")
  const dispatch = useDispatch();
  useEffect(() => {
    if (token) {
      loginWithToken(dispatch, token)
    }
  }, [token])

  if (auth.isLoggedIn && location.pathname !== "/auth/resetpassword") {
    return <Redirect to={{
      pathname: "/",
      search: location.search
    }} />
  }


  const renderAuth = () => {
    if (token) return <div>Validating User... <CircularProgress size={17} /> </div>
    else
      return <div style={{ margin: '60px 30px' }}>
        {console.log(studentId, token)}
        <div style={{ color: '#FFBC36', font: 'normal normal 800 28px/38px tablet-gothic' }}>myApp</div>
        <div className="mb-15" style={{ font: 'normal normal 900 32px/34px Source Serif Pro', letterSpacing: '0.32px' }}>Starmaker Associate Academy</div>
        <div className="text-center mt-70">
          <span>New User?</span>
          <Link to={{ pathname: "/auth/register", search: window.location.search }} style={{ textDecoration: 'none', color: 'inherit' }} >
            <Button className="special-button bg-white mt-15 border-red br-18 hover-bg-red hover-color-white" fullWidth style={{ marginTop: "15px" }}>
              Register
            </Button>
          </Link>
        </div>
        <div className="text-center mt-15">
          <span>Already have an account?</span>
          <Link to={{ pathname: "/auth/login", search: window.location.search }} style={{ textDecoration: 'none', color: "inherit" }}>
            <Button className="special-button mt-15 hover-bg-red hover-color-white" fullWidth style={{ marginTop: "15px" }}>
              Login
            </Button>
          </Link>
        </div>
        <div className="copyright mt-30 text-center">Copyright 2019-21; Illumine. All rights reserved.</div>
      </div>
  }

  return (
    <Switch>
      <Route path="/auth/login" component={Login} />
      <Route path="/auth/register" component={Register} />
      <Route path="/auth/forgot-password" component={ForgotPassword} />
      <Route path="/auth/resetpassword" component={ResetPassword} />
      <Route path="/auth" render={renderAuth} />
    </Switch>
  );
};

export default Auth;
