import { Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import SwipeableViews from "react-swipeable-views";

export default ({ walkthroughImages, buttonStyle, buttonClasses, buttonText, buttonAction }) => {
    useEffect(() => {
        document.body.style.backgroundColor = "#FFF";
    })

    return <SwipeableViews enableMouseEvents>
        {walkthroughImages.map((step, index) => <> <img
            src={step}
            style={{ width: "100%", height: "100vh", position: "relative" }}
        />
            {walkthroughImages.length == index + 1 ? <Button
                onClick={buttonAction}
                style={buttonStyle}
                className={buttonClasses}>
                <span style={{ width: "75%", 'textAlign': 'left', 'paddingLeft': '5%' }}>{buttonText}</span>
                <span style={{ width: '25%', fontFamily: 'FF-TISA-SANS-WEB-PRO' }}>&nbsp; &nbsp; &gt;&gt;</span>
            </Button> : ''}
        </>
        )}
    </SwipeableViews>
}