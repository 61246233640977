import React from 'react';
import { Player, ControlBar } from "video-react"
import "video-react/dist/video-react.css";
import "./index.css"

export default ({ src, poster }) => {
    return <Player
        src={src}
        playsInline
        fluid={false}
        poster={poster}
        videoWidth={"100vw"}
        videoHeight={"100vh"}
        style={{ border: 'none' }}
    >
        <ControlBar />
    </Player>
}