import React, { useState } from "react"
import { Button, CircularProgress } from "@material-ui/core"
import { Redirect } from "react-router";
import CloseDialog from "./CloseDialog";

export default ({ unit, close, showCloseDialog, setClose, setShowFlashpoint, history }) => {

    const [imgLoad, setImgLoad] = useState(false);

    const imgOnLoad = () => { setImgLoad(true) }

    if (Object.keys(unit).length > 0)
        return <div className="w-100 h-100" style={{ backgroundColor: unit?.color, position: 'relative', display: 'flex', flexDirection: "column", justifyContent: "space-between", padding: '5%', alignItems: "center" }}>
            <CloseDialog unitCompleted={unit?.isCompleted} buttonClass='fp-fullscreen-img-close-button' />
            {/* <Button className="text-decoration-none fp-fullscreen-img-close-button" classes={{
                label: 'small-text'
            }} onClick={() => unit?.isCompleted ? window.location.href = "/flashpoints" : setClose(true)}> CLOSE X</Button>
            {close && showCloseDialog()} */}
            <div className='fp-card-title' style={{ marginTop: "10%" }}>{unit.title}</div>
            <img src={unit?.img_src} onLoad={imgOnLoad} style={{ width: 'fit-content', height: 'fit-content' }} />
            {!imgLoad && <CircularProgress />}
            <Button className='fp-card-view-fp-button'
                onClick={setShowFlashpoint}
            // onClick={() => {
            //     setShowFlashpoint(true);                 
            //     window.location.search += "?flashpoint=true"
            // }}
            >
                <span style={{ width: '60%' }}>{unit?.button}</span>
                <span style={{ fontSize: '22px' }}>&nbsp; &nbsp; &gt;&gt;</span>
            </Button>
        </div>
    else return <CircularProgress style={{ position: "absolute", left: "45%", top: "45%" }} size={70} />
}