import React, { useState, useLayoutEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Switch, Route, useHistory } from "react-router-dom";
import AlternativeResponses from "./alternativeResponses";
import FlashpointsDashBoard from "./dashboard";
import SubmitScreen from "./SubmitScreen";
import FlashpointUnit from "./unit"
import Flashpoint from "./Flashpoint"
import SuccessStory from "./Success_Stories/StoryTextBox"

import ScrollDownButton from './assets/Scroll down 3.svg'

import './utils.css'
export default () => {
    let history = useHistory();
    const { app, auth } = useSelector(state => state);
    const flashPointRootRef = useRef('');
    const [scrollBottom, setScrollBottom] = useState(false);


    useLayoutEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
        toggleVisibleScroll();
        return () => { }
    }, [history.location.pathname])


    const scrollToBottom = () => {
        window.scrollTo({
            top: flashPointRootRef.current.scrollHeight,
            behavior: 'smooth'
        });
    };


    const toggleVisibleScroll = () => {
        if ((window.innerHeight + window.scrollY) > (flashPointRootRef?.current?.scrollHeight - 50)) setScrollBottom(false);
        else if (flashPointRootRef?.current?.clientHeight < flashPointRootRef?.current?.scrollHeight) setScrollBottom(true);
    };

    window.addEventListener('scroll', toggleVisibleScroll);

    return (
        <div ref={flashPointRootRef} className='flashpoint'>
            <Switch>
                <Route exact path="/flashpoints/unit/:unitId/submit"
                    render={() => <SubmitScreen history={history} app={app} auth={auth} />} />
                <Route exact path="/flashpoints/unit/:unitId/response"
                    render={() => <AlternativeResponses history={history} app={app} auth={auth} />} />
                <Route exact path="/flashpoints/unit/:unitId/SuccessStory"
                    render={() => <SuccessStory history={history} app={app} auth={auth} />} />
                <Route exact path="/flashpoints/unit/:unitId/flashpoint"
                    render={() => <Flashpoint history={history} app={app} auth={auth} />} />
                <Route exact path="/flashpoints/unit/:unitId"
                    render={() => <FlashpointUnit history={history} app={app} auth={auth} />} />

                {/* <Route exact path="/flashpoints/unit/:unitId" component={FlashpointUnit} /> */}
                <Route exact path="/flashpoints" component={FlashpointsDashBoard} />
                {/* <Route exact path="/flashpoints/units" component={FlashpointUnit} /> */}
                {/* <Route exact path="/flashpoints/unit/61a9f1be338b1122a09ad6a1" component={FlashpointUnit} /> */}
                {/* <Route exact path="/flashpoints" component={FlashpointsRules} /> */}
            </Switch>
            {scrollBottom && <img src={ScrollDownButton} className='fp-unit-floating-button' onClick={scrollToBottom} alt='scroll' />}
        </div>
    );
};