import React from 'react';
import SA_LS_02_IT from "./StarMaker_Associate/LS_02_IT"
import MultiPageTemplate from '../components/MultiPagePDFReader';
import Video from './Video';
import { useSelector } from "react-redux";

const duringLoad = <div className='loading-screen'>Loading...</div>

const Takeaways = {
    SA_LS_02_IT,
    MultiPageTemplate,
    Video
}

export default ({ data, onBack, uid, }) => {
    let Tkaway = Takeaways[data.type]
    let { auth } = useSelector(state => state)

    return <>
        <Tkaway
            takeawayId={data._id}
            src={data.src}
            poster={data.poster}
            duringLoad={duringLoad}
            resourceId={data._id}
            uid={uid}
            docRatio='0.65'
            onBack={onBack}
            user_token={auth.user_token}
        />
    </>
}