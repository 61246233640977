import React from "react"
export default ({ }) => {
  return <div style={{}} className="redirect-page">
    <p style={{ color: "#b40000" }}>Welcome to the StarMaker Program</p>
    <p> <b>The Pedgog app has been upgraded.</b></p>
    <p>You can install the latest version of the <b>Pedgog app </b> here:</p>
    {/* <p>Download From
      {type.os() == "iOS" && <a href="https://apple.co/3v0Hy0f">Apple Store</a>}
      {type.os() == "AndroidOS" && <a href="https://bit.ly/3v5DH26">Android Store</a>}
    </p> */}
    <p>Andoid users use this link: <a href="https://bit.ly/3v5DH26">https://bit.ly/3v5DH26</a></p>
    <p>iPhone users use this link: <a href="https://apple.co/3v0Hy0f">https://apple.co/3v0Hy0f</a></p>
    <div style={{ margin: "5% auto", width: "fit-content" }}>
      <img src={"/illumine.svg"} />
    </div>
  </div >
}