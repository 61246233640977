import React, { useEffect } from 'react';
import { Button } from '@material-ui/core';
import { ChevronRight as ChevronRightIcon, ChevronLeft as ChevronLeftIcon } from '@material-ui/icons';
import createDOMPurify from "dompurify";
import { Link } from 'react-router-dom';
import { getFlashpointData, manageToolData } from '../../redux_actions';

const DOMPurify = createDOMPurify(window);

export default function ActionsInAccordionSummary({ content, next, alternateResponseDataLength, showDescription, setShowDescription, unitId, alternateResponseLoading, app, auth }) {

    useEffect(() => {
        getFlashpointData(auth.user_token, unitId, auth.user_uid).then(res => {
            if (res?.flashpointData) {
                Object.keys(res.flashpointData).map(key => {
                    manageToolData({ toolId: key, content: { ...res.flashpointData[key], entityMap: {} } })
                })
            }
        })
    }, [])

    return (
        <>
            <div className='fp-points-description'>
                <div className={`font-weight-bold ${showDescription === -1 ? 'fp-points-title' : 'fp-point-display-none'}`} style={{ padding: '6%' }}>

                    <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content?.title) }}></div>
                </div>
                <div style={{ height: '84%' }}>
                    <div style={{ height: '90%' }}>
                        <div className='fp-unit-html-template' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content?.description) }} />
                    </div>
                </div>
            </div>

            <Link className='text-decoration-none' to={{ pathname: `/flashpoints/unit/${unitId}/SuccessStory` }}  >
                <Button className="fp-unit-button" onClick={next}>
                    <span style={{ width: '80%', position: 'relative' }}>
                        Use the Success Story Builder!
                    </span>
                    <span>&nbsp; &nbsp; &gt;</span>
                </Button>
            </Link>
        </>
    );
}
