import React, { useEffect, useState } from 'react';
import { Button, InputAdornment, TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { Link } from 'react-router-dom';
import FP_Icon from './assets/Flashpoint_Icon.svg'
import FP_GymIcon from './assets/Flashpoint_GymIcon.svg'
import './style.css'
import { getScore, getTags, searchFlashpointByTag, getFlashpointUnits, firstTimeUser, setFirstTimeUse } from '../redux_actions';
import { useSelector } from 'react-redux';
import { Home as HomeIcon } from '@material-ui/icons';

import { WhatsappShareButton, WhatsappIcon } from "react-share";
import Walkthrough from '../../../components/Walkthrough';
import MenuBar from '../../../components/MenuBar';
import store from '../../../Redux_Store';

export default () => {
    const { user_token, user_uid, userAccount } = useSelector(state => state.auth)
    const app = useSelector(state => state.app)
    const [searchText, setSearchText] = useState('');
    const [tags, setTags] = useState([])
    const [todayUnit, setTodayUnit] = useState()
    const [fp, setFp] = useState([]);
    const [allFp, setAllFp] = useState([]);
    const [showAllFp, setShowAllFp] = useState(true);
    const [showFpResult, setShowFpResult] = useState('text');
    const [imgLoad, setImgLoad] = useState({});
    // const [showWalkthrough, setShowWalkthrough] = useState(true);


    useEffect(() => {
        // firstTimeUser(user_token, userAccount.id).then((res) => {
        //     setShowWalkthrough(!res?.flashpointOpened)
        // })
        store.dispatch({ type: 'RESET_FP_VALUES' })

        getTags(user_token).then(res => setTags(res));
        getScore(user_token, user_uid).then(res => setTodayUnit(res));
        getFlashpointUnits(user_token, user_uid).then(res => setAllFp(res));
        document.body.style.backgroundColor = '#FFF8EC'
    }, [])

    const walkthroughImages = [
        "https://s3.ap-south-1.amazonaws.com/myapp.resources/FlashPoints/Walkthrough/FP+-+intro.svg",
        'https://s3.ap-south-1.amazonaws.com/myapp.resources/FlashPoints/Walkthrough/FP+1.svg',
        'https://s3.ap-south-1.amazonaws.com/myapp.resources/FlashPoints/Walkthrough/FP+2.svg',
        'https://s3.ap-south-1.amazonaws.com/myapp.resources/FlashPoints/Walkthrough/FP+3.svg',
        'https://s3.ap-south-1.amazonaws.com/myapp.resources/FlashPoints/Walkthrough/FP+4.svg'
    ];

    const searchFlashPoint = () => {
        if (searchText) {
            searchFlashpointByTag(user_uid, user_token, undefined, searchText).then(res => {
                if (res?.length > 0) setShowFpResult(true);
                else setShowFpResult(false);
                setFp(res);
            });
            setShowAllFp(true);
        }
        else setShowFpResult(false);
    }

    if (!app?.showWalkthrough?.flashpointOpened)
        return <Walkthrough walkthroughImages={walkthroughImages}
            buttonStyle={{ position: "absolute", bottom: "8%", left: `${(walkthroughImages.length - 1) * 100 + 14}%`, width: "70%" }}
            buttonClasses={'walkthrough-button'}
            buttonText="Get Started"
            buttonAction={() => {
                // setShowWalkthrough(false);

                store.dispatch({
                    type: "SHOW_WALKTHROUGH", payload: {
                        'flashpointOpened': true
                    }
                })
                setFirstTimeUse(user_token, userAccount.id, { flashpointOpened: true })
            }}
        />
    else
        return (
            <div className='h-100' style={{ backgroundColor: '#FFF8EC' }}>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <MenuBar leftComponent={<div className='font-weight-bold' style={{
                        display: 'flex',
                        paddingTop: '3%',
                        alignItems: 'center'
                    }}>
                        <img src={FP_Icon} className='fp-icon' alt='fp-icon' />
                        <div style={{ lineHeight: '1.2' }}>
                            Flashpoint <br />Finder
                        </div>
                    </div>} />
                </div>
                {/* <HomeIcon style={{ width: "10%", height: "10%" }} className='ml-auto' onClick={() => window.location.href = "/"} /> */}
                <TextField
                    variant="filled"
                    label="Search a flashpoint"
                    fullWidth
                    type="text"
                    autoComplete="on"
                    style={{ margin: '4% 0%' }}
                    InputProps={{
                        style: { backgroundColor: "#BA1F00", borderRadius: '12px', color: 'white', boxShadow: '0px 3px 6px #00000029' },
                        disableUnderline: true,
                        endAdornment: <InputAdornment position="end">
                            <div className='fp-dashboard-search-icon'>
                                <SearchIcon style={{ color: 'black', cursor: 'pointer' }} onClick={searchFlashPoint} />
                            </div>
                        </InputAdornment>
                    }}
                    InputLabelProps={{
                        style: { color: 'white' }
                    }}
                    value={searchText}
                    onChange={event => setSearchText(event.target.value)}
                    onKeyDown={event => { if (event.key === 'Enter') searchFlashPoint(); }} />
                {/* <div className="ap-login-field-container mb-15" style={{ padding: '5px', margin: '15% 0%' }}>
               
            </div> */}

                {
                    tags.length > 0 ? <><span>SEARCH BY AREA</span>
                        <div className='d-flex flex-wrap overFlowYAuto textbox-scrollable-darkgray' style={{ maxHeight: '25vh' }}>
                            {
                                tags?.map((tag) => <div key={tag} className={`fp-dashboard-tag ${tag === searchText ? 'fp-dashboard-tag-active' : 'null'}`} onClick={() => {
                                    setSearchText(tag);
                                    searchFlashpointByTag(user_uid, user_token, tag).then(res => { setFp(res); setShowFpResult(true); })
                                    setShowAllFp(true);
                                }}>{tag}</div>)
                            }
                        </div> </> : ""
                }

                <div>{fp?.length > 0 && showFpResult && <div className='fp-dashboard-results'>Results : {fp.length}</div>}</div>
                <div style={{ backgroundColor: '#FFF8EC', margin: '0% -2%' }}>
                    {

                        fp?.length > 0 && showFpResult ? fp.map(fp =>
                            <div key={fp._id} hidden={!imgLoad?.[fp.img_src]} style={{ position: 'relative', margin: '10% 0%', padding: '0 2%', backgroundColor: fp.color, padding: '10%', filter: "drop-shadow(rgba(0, 0, 0, 0.16) 0px 3px 6px)", borderRadius: "25px" }}>
                                {/* <img src={FP_Icon} alt='fp-icon' style={{
                                    width: '12%',
                                    position: 'absolute',
                                    top: '-3.5%',
                                    right: '45%'
                                }} /> */}
                                {/* <div style={{ position: 'absolute', top: '6%', right: '38%' }}>FLASHPOINT</div> */}
                                <div className='fp-card-title' >{fp.title}</div>
                                <img src={fp?.img_src} alt='' className='w-100 h-auto' onLoad={() => setImgLoad((oldValue) => {
                                    return {
                                        ...oldValue,
                                        [fp.img_src]: true
                                    }
                                })} />

                                <Link to={`/flashpoints/unit/${fp._id}`} style={{textDecoration: "none"}}>
                                    <Button className='fp-card-view-fp-button'>
                                        <span style={{ width: '60%' }}>{fp?.button || "How to convince the customer?"}</span>
                                        <span style={{ fontSize: '1.5rem' }}>&nbsp; &nbsp; &gt;&gt;</span>
                                    </Button>
                                </Link>
                            </div>) : showFpResult !== 'text' && !showFpResult && <div className='fp-dashboard-results '>No results found.</div>
                    }
                </div>
                {
                    todayUnit && <div className='fp-dashboard-score-card'>
                        <img src={FP_GymIcon} style={{ width: '25%' }} alt='fp-gym' />
                        <div className='font-weight-bold'>Flashpoint Gym Score</div>
                        {/* <div>
                            <span>Day 02 of 60</span>
                        </div> */}
                        <div style={{ fontWeight: 'bold', color: '#BA1F00' }}>
                            <span style={{ fontSize: '4rem', lineHeight: 1.1 }}>{todayUnit?.score}</span>/{todayUnit?.total} points
                        </div>
                        <div className='font-style-italic font-weight-bold' style={{ margin: '3% 0%' }}>{todayUnit?.text}</div>
                        {
                            todayUnit?.today ? < Link className='text-decoration-none' to={`/flashpoints/unit/${todayUnit?.today?._id}`}>
                                <Button className='fp-dashboard-score-unit-button'>
                                    <span style={{ width: '75%' }}>See Today's Unit</span>
                                    <span style={{ width: '25%' }}>&nbsp; &nbsp; &gt;&gt;</span>
                                </Button>
                            </Link> : <Button className='fp-dashboard-score-unit-button gray-background justify-content-center'>
                                You are done for the day!
                            </Button>
                        }
                    </div>
                }
                {
                    showAllFp && allFp.length > 0 && <Button className='fp-view-all-fp-button' onClick={() => { setFp(allFp); setShowFpResult(true); setSearchText(''); setShowAllFp(false); }}>
                        <span style={{ width: '85%' }}>View all flashpoints ({allFp.length})</span>
                        <span style={{ width: '15%' }}>&nbsp; &nbsp; &gt;&gt;</span>
                    </Button>
                }
            </div >
        )
}
