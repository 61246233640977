import firebase from "firebase/app";
import 'firebase/database'
import config from "../../config"
import _ from "lodash";

// var firebaseConfig = {
//     apiKey: "AIzaSyCEt3wyrOuWlNmbg9C3O40DzhgOT3MWvFk",
//     authDomain: "illumine-socket-test.firebaseapp.com",
//     databaseURL: "https://illumine-socket-test-default-rtdb.firebaseio.com",
//     projectId: "illumine-socket-test",
//     storageBucket: "illumine-socket-test.appspot.com",
//     messagingSenderId: "509881491143",
//     appId: "1:509881491143:web:bdfcb471a81883797868e2"
// };

const firebaseConfig = {
    apiKey: "AIzaSyAbRUjiODv61sHpQXXSng2AOINvEc30X_U",
    authDomain: "pedgog-data-exchange.firebaseapp.com",
    databaseURL: "https://pedgog-data-exchange-default-rtdb.firebaseio.com",
    projectId: "pedgog-data-exchange",
    storageBucket: "pedgog-data-exchange.appspot.com",
    messagingSenderId: "803694997881",
    appId: "1:803694997881:web:e23a89e05289e9476d3fa8"
};

var stop = true;
var eventQueue = []
let eventList = [];
var eventTypeAndHandler, senderType, senderId, receiveId, sessionid, _receiveType, _engagementId
var enableEngagementRef, engagementRef

firebase.initializeApp(firebaseConfig);
const database = firebase.database()
let dataRef = database.ref()

async function initalize(engagementId, etah, userType, userId, receiveType, otp) {
    // Stoing Variables
    eventTypeAndHandler = etah
    senderType = userType
    senderId = userId
    _receiveType = receiveType
    _engagementId = engagementId

    const session = await getSessionDetail(otp)
    console.log(session, otp)
    sessionid = session._id
    receiveId = sessionid

    enableEngagementRef = dataRef.child(`live_session/${sessionid}/enableEngagement`)
    engagementRef = dataRef.child(`live_session/${sessionid}/${_engagementId}`)
    startEngagement()
}

async function init(etah, userType, username, userId, receiveType, otp) {
    // Stoing Variables
    eventTypeAndHandler = etah
    senderType = userType
    senderId = userId
    _receiveType = receiveType

    const session = await getSessionDetail(otp)

    if (session) {
        sessionid = session._id

        receiveId = sessionid
        enableEngagementRef = dataRef.child(`live_session/${sessionid}/enableEngagement`)
        joinSession({ username, userId })
        startEngagement()
        dataRef.child(`live_session/${sessionid}/Participants/${userId}/status`).onDisconnect().set("offline")
    }
}

async function startEngagement() {
    receiveEvent()
    loop()
}

async function joinSession(data) {
    _engagementId = undefined;
    sendEvent("JOIN_SESSION", data)
}

async function assessmentDone(sessionid, coachId, userId) {
    let payload = {
        senderType: "Student",
        senderId: userId,
        eventName: "ASSESSMENT_DONE",
        eventData: {},
        receiveType: "Coach",
        receiveId: coachId,
        engagementId: ""
    }
    console.log(payload)
    dataRef.child(`live_session/${sessionid}/Participants/${userId}/assessment`).set(payload)
}

async function getSessionDetail(otp) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
        otp
    });

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    let session
    await fetch(config.API_URL + "session/getSessionByOTP", requestOptions)
        // await fetch("http://localhost:5001/v1/session/getSessionByOTP", requestOptions)
        .then(response => response.json())
        .then(result => { if (!result.code) return session = result })
        .catch(error => console.log('error', error));

    return session;
}

function receiveEvent() {
    stop = false
    if (_engagementId) {
        engagementRef.on("value", (snapshot) => {
            let payload = snapshot.val()
            for (let key in payload) {
                let event = payload[key];
                if (_.filter(eventList, _.matches(key)).length === 0) {
                    eventQueue.push(event)
                    eventList.push(key)
                }
            }
        })
    }
    else {
        enableEngagementRef.on("value", (snapshot) => {
            let engagements = snapshot.val()
            console.log(engagements)
            for (let eng in engagements) {
                let payload = engagements[eng]
                eventQueue.push(payload)
            }
        })
    }
}

async function loop() {
    while (!stop || eventQueue.length > 0) {
        let first = eventQueue.shift();
        if (first) {
            eventTypeAndHandler(first);
        }
        else
            await sleep(1)
    }
}

function sendEvent(eventName, eventData) {
    let payload = {
        senderType, senderId, eventName, eventData, receiveType: _receiveType, receiveId, engagementId: _engagementId
    }
    if (_engagementId)
        dataRef.child(`live_session/${sessionid}/${_engagementId}`).push(payload)

    dataRef.child(`live_session/${sessionid}/Participants`).on('value', (snapshot) => {
        if (snapshot.val() && Object.keys(snapshot.val()).includes(eventData.userId)) {
            return
        }
        payload["engagementId"] = ""
        let userId = !eventData.userId ? senderId : eventData.userId
        dataRef.child(`live_session/${sessionid}/Participants/${userId}`).set({ attendence: payload, status: "online" })
        dataRef.child(`live_session/${sessionid}/Participants`).off('value')
    })
}


function stopEngagement() {
    stop = true;
    engagementRef.off()
    // enableEngagementRef.off()
}

function sleep(second) {
    return new Promise(resolve => setTimeout(resolve, second * 1000));
}

export default {
    init,
    initalize,
    sendEvent,
    stopEngagement,
    startEngagement,
    assessmentDone
}