const INITIAL_STATE = {
  myModules: null,
  engList: {},
  checkOTPError: '',
  otp: {},
  engagementData: {},
  enableEngagement: [],
  allGroupData: [],
  selectedGroupName: '',
  quizData: {},
  testsubmission: {},
  attendedSessions: {},
  score: {},
  fpSelectedUnit: {},
  showWalkthrough: {},
  alternateResponse: [],
  fpScore: {},
  fpAlternateResponseLoading: false,
  fpScoreLoading: false,
  fpSelectedUnitLoading: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "Navigation/NAVIGATE":
      return state;
    case "RESET_APP":
      return { ...INITIAL_STATE };
    case "UPDATE_USERID":
      return { ...state, userId: action.data };
    case "UPDATE_USER_ACCOUNT":
      return { ...state, userAccount: action.data };
    case "MANAGE_TOOL_DATA":
      // console.log(state.engagementData);
      return {
        ...state,
        engagementData: {
          ...state.engagementData,
          [action.payload.toolId]: action.payload.content,
        },
      };

    case "UPDATE_MODULES":
      return {
        ...state, myModules: {
          ...state.myModules,
          [action.data.id]: action.data
        },
        engList: '',
        group: []
      };
    case "UPDATE_UNITS":
      return { ...state, units: { ...state.units, [action.data.id]: action.data.detail } };
    case "CURRENT_MODULE":
      return { ...state, units: { ...state.units, [action.data.id]: action.data.detail } };
    case "TAB_INDEX_CHANGE":
      return { ...state, tabIndex: action.value }
    case "UPDATE_TRACKER":
      return { ...state }
    case "CHECKOTP_SUCCEDED":
      return { ...state, engList: action.payload.engagement, otp: { ...state.otp, [action.payload.otp.moduleId.id]: action.payload.otp }, checkOTPError: '', enableEngagement: [], engagementData: {}, allGroupData: [], selectedGroupName: '' }
    case "RESET_ENABLE_ENGAMENT":
      return { ...state, enableEngagement: [], allGroupData: [], engagementData: {} }
    case "CHECKOTP_FAILURE":
      return { ...state, checkOTPError: 'OTP is wrong' }
    case "ENABLE_ENGAGEMENT":
      return { ...state, enableEngagement: [...state.enableEngagement, action.payload] }
    case "DISABLE_ENGAGEMENT": {
      return { ...state, enableEngagement: state.enableEngagement.filter(item => item !== action.payload) }
    }
    case "ASSIGN_GROUP": {
      let groupName;
      console.log(action.payload)
      // for (let key in Object.keys(action.payload.data)) {
      //   let data = action.payload.data[key]
      //   if (key, data["item"])
      //     for (let i in data["item"]) {
      //       let item = data["item"][i]
      //       if (item.userId === action.payload.userId)
      //         groupName = key
      //     }
      // }
      Object.keys(action.payload.data).map((key) => {
        if ((action.payload.data[key]).includes(action.payload.userId)) {
          groupName = key
        }
      })
      // if (action.payload.data[key]["item"] && Object.keys(action.payload.data[key]["item"]).includes(action.payload.userId))
      //   groupName = key;
      // })
      return { ...state, allGroupData: action.payload.data, selectedGroupName: groupName }
    }
    case "QUIZ_DATA":
      return {
        ...state, quizData: {
          ...state.quizData, [action.payload._id]:
          {
            ...state.quizData[action.payload._id],
            ...action.payload
          }
        }
      }
    // case "CURRENT_ASSESSMENT_FINISH":
    //   console.log()
    //   return {
    //     ...state, quizData: {
    //       ...state.quizData,
    //       [action.payload.testid]: { ...state.quizData[action.payload.testid], status: "taken" }
    //     }
    //   }
    case "TEST_RESULT":
      return {
        ...state, quizData: {
          ...state.quizData,
          [action.payload.testid]: { ...state.quizData[action.payload.testid], result: action.payload.score }
        }
      }
    case "TEST_SUBMISSION":
      return {
        ...state, testsubmission: {
          ...state.testsubmission, [action.data]: {
            ...state.testsubmission[action.data],
            ...action.payload
          }
        }
      }
    case "ATTENDED_SESSION":
      return { ...state, attendedSessions: action.payload }
    case "STUDENT_RESULT":
      return { ...state, score: action.payload }
    // return state
    case "FLASH_POINTS_SELECTED_UNIT":
      return { ...state, fpSelectedUnit: action.payload }
    case "SHOW_WALKTHROUGH":
      return {
        ...state,
        showWalkthrough: {
          ...state.showWalkthrough,
          ...action.payload
        }
      }
    case "SELECTED_FP_UNIT_LOADING":
      return { ...state, fpSelectedUnit: [], fpSelectedUnitLoading: true }
    case "SELECTED_FP_UNIT":
      return { ...state, fpSelectedUnit: action.payload, fpSelectedUnitLoading: false, alternateResponse: [] }
    case "ALTERNATE_RESPONSE_LOADING":
      return { ...state, alternateResponse: [], fpAlternateResponseLoading: true }
    case "ALTERNATE_RESPONSE": {
      if (action?.payload[0]?.flashpointId && state.fpSelectedUnit?._id !== action?.payload[0]?.flashpointId)
        return { ...state, alternateResponse: [], fpAlternateResponseLoading: true }
      return { ...state, alternateResponse: action.payload, fpAlternateResponseLoading: false }
    }
    case "LOAD_ALTERNATE_RESPONSE_LOADING": {
      return { ...state, fpAlternateResponseLoading: true }
    }
    case "LOAD_ALTERNATE_RESPONSE": {
      return {
        ...state, alternateResponse: [
          ...state.alternateResponse,
          ...action.payload
        ], fpAlternateResponseLoading: false
      }
    }
    case "FP_SCORE_LOADING":
      return { ...state, fpScore: {}, fpScoreLoading: true }
    case "FP_SCORE":
      return { ...state, fpScore: action.payload, fpScoreLoading: false }
    case "ADD_ALTERNATE_RESPONSE":
      return { ...state, alternateResponse: [action.payload, ...state.alternateResponse] }
    case "RESET_FP_VALUES":
      return { ...state, fpSelectedUnit: {}, alternateResponse: [], fpScore: {}, fpScoreLoading: false }
    default:
      return state;
  }
};
