import React, { useState, useLayoutEffect } from 'react';
import { Button, CircularProgress } from '@material-ui/core';
import { ChevronRight as ChevronRightIcon, ChevronLeft as ChevronLeftIcon } from '@material-ui/icons';
import createDOMPurify from "dompurify";
import { Link } from 'react-router-dom';

const DOMPurify = createDOMPurify(window);

export default function ActionsInAccordionSummary({ content, next, alternateResponseDataLength, showDescription, setShowDescription, unitId, alternateResponseLoading, app }) {

    return (
        <>
            <div className='fp-points-description'>
                <div className={`font-weight-bold ${showDescription === -1 ? 'fp-points-title' : 'fp-point-display-none'}`}>
                    {showDescription === -1 && <img src={content?.icon} className='fp-icon' alt='icon' />}
                    <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content?.title) }}></div>
                </div>
                <div style={{ height: '84%' }}>
                    {
                        showDescription === -1 ?
                            <ol style={{ listStyleType: "none" }}>
                                {content?.items?.map((item, index) =>
                                    <li key={item.title}
                                        onClick={() => item?.description ? setShowDescription(index) : null}
                                    >
                                        <div className='point-holder'>
                                            <span>{index + 1}.</span>
                                            <span style={{ padding: '0 0.5rem' }} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.title) }} />
                                            {item?.description ? <ChevronRightIcon className='right-arrow-icon' /> : null}
                                        </div>
                                    </li>)}
                            </ol>
                            : <>
                                <div style={{ height: '90%' }}>
                                    <div className='font-weight-bold' style={{ margin: '2% 6% 0% 6%', paddingTop: "7%" }}>{content.items[showDescription].title}</div>
                                    {/* <ul className='point-description'>
                                        {
                                            content.items[showDescription].description.map(list => <li key={list}>{list}</li>)
                                        }
                                    </ul> */}

                                    <div className='fp-unit-html-template' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content.items[showDescription].description) }} />
                                </div>
                                <div className='fp-unit-back-button'>
                                    <div className='flex-center '
                                        onClick={() => setShowDescription(-1)}
                                    >
                                        <ChevronLeftIcon className='fp-unit-back-left-arrow-icon' />
                                        &nbsp; Back
                                    </div>
                                </div>
                            </>
                    }
                </div>
            </div >
            {/* {
                showDescription === -1 && <Button className="fp-unit-button" onClick={next}>
                    <span style={{ width: '80%' }}>Next: See other solutions from associates ({alternateResponseDataLength})</span>
                    <span>&nbsp; &nbsp; &gt;&gt;</span>
                </Button>
            } */}
            {
                showDescription === -1 &&
                <Link className='text-decoration-none' to={`/flashpoints/unit/${unitId}/response`} >
                    <Button className="fp-unit-button" onClick={next}>
                        {/* <span style={{ width: '80%', position: 'relative' }}>
                            Next: See other solutions from associates {alternateResponseLoading ? <>&nbsp;&nbsp;&nbsp;<CircularProgress className='alternate-response-number-loading' /></> : `(${alternateResponseDataLength})`}  </span> */}
                        <span style={{ width: '80%', position: 'relative' }}>
                            Next: See other solutions from associates  ({alternateResponseDataLength})</span>
                        <span>&nbsp; &nbsp; &gt;&gt;</span>
                    </Button>
                </Link>
            }
        </>
    );
}
