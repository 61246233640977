import React from "react";
import PropTypes from "prop-types";
import {
  IconButton,
  InputLabel,
  InputAdornment,
  FormControl,
  Button,
  TextField,
  FilledInput,
  FormHelperText,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { Visibility, VisibilityOff } from "@material-ui/icons";

import "./LoginForm.css";
import "./styles.css"
const styles = {};

class Loginform extends React.Component {
  constructor(props) {
    super(props);
    document.body.style.backgroundColor = "#FFF";
    this.state = {
      email: "",
      password: "",
      showPassword: false,
      fields: {},
      errors: {},
      label: "",
    };
    

    this.handleChange = (prop) => (event) => {
      this.setState({ [prop]: event.target.value });
    };

    this.handleMouseDownPassword = (event) => {
      event.preventDefault();
    };

    this.handleClickShowPassword = () => {
      this.setState({ showPassword: !this.state.showPassword });
    };
  }

  handleValidation(eml, pwd) {
    this.setState({ label: "" });
    let errors = {};
    let formIsValid = true;

    //Email
    if (!eml) {
      formIsValid = false;
      errors["user-email"] = "Email cannot be empty";
      this.setState({ label: "Email can not be empty" });
    }
    //Password
    if (!pwd) {
      formIsValid = false;
      errors["user-password"] = "Password cannot be empty";
      this.setState({ label: "Password can not be empty" });
    }

    if (typeof eml !== "undefined") {
      let lastAtPos = eml.lastIndexOf("@");
      let lastDotPos = eml.lastIndexOf(".");

      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          eml.indexOf("@@") == -1 &&
          lastDotPos > 2 &&
          eml.length - lastDotPos > 2
        )
      ) {
        formIsValid = false;
        errors["user-email"] = "Email is not valid";
        this.setState({ label: "Email can not be empty" });
      }
    }
    this.setState({ errors: errors });
    return formIsValid;
  }

  render() {
    const { classes } = this.props;
    const toRegister = (props) => <Link to="../auth/register" {...props} />;
    const toResetPassword = (props) => (
      <Link to="../auth/reset-password" {...props} />
    );

    return <div style={{ margin: '60px 30px' }}>
      <div style={{ color: '#FFBC36', font: 'normal normal 800 28px/38px tablet-gothic' }}>myApp</div>
      <div className="mb-15" style={{ font: 'normal normal 900 32px/34px Source Serif Pro', letterSpacing: '0.32px' }}>Starmaker Associate Academy</div>
      <div className="ap-login-field-container mb-15">
        <TextField
          variant="filled"
          label="Email"
          fullWidth
          type="text"
          autoComplete="on"
          InputProps={{ style: { backgroundColor: "#FFFFFF" }, disableUnderline: true }}
          value={this.state.email}
          onChange={this.handleChange("email")} />
      </div>
      <div className="ap-login-field-container">
        <FormControl fullWidth className={"input-cntr"} variant="filled">
          <InputLabel htmlFor="adornment-password">Password</InputLabel>
          <FilledInput
            id="user-password"
            label="Password"
            autoComplete="off"
            disableUnderline
            type={this.state.showPassword ? "text" : "password"}
            inputProps={{ style: { backgroundColor: "#FFFFFF" } }}
            value={this.state.password}
            onChange={this.handleChange("password")}
            helperText
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="Toggle password visibility"
                  onClick={this.handleClickShowPassword}
                  onMouseDown={this.handleMouseDownPassword}
                >
                  {this.state.showPassword ? (
                    <VisibilityOff />
                  ) : (
                    <Visibility />
                  )}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
      </div>
      {this.state.label ? <div className="errorMsg">{this.state.label}</div> : ""}
      {this.props.authMsg ? <div className="errorMsg">{this.props.authMsg}</div> : ""}
      <FormControl
        className={"input-cntr"}
        style={{ display: "flex", alignItems: "flex-end" }}
      >
        <Link
          className="forgot-pw"
          // component={Link}
          to="/auth/forgot-password"
          style={{
            margin: "10px 0",
            textDecoration: "none",
            color: "#b9b9b9",
            fontSize: ".815rem",
          }}
        >
          Forgot Password
        </Link>
      </FormControl>
      <Button
        fullWidth
        className="login-button"
        onClick={() => {
          let { email, password } = this.state;
          let chkVal = this.handleValidation(email, password);

          if (chkVal) {
            this.props.loginAttempt({
              email,
              password,
              token: this.props.token,
              successCallBack: () => {
                //alert('success');
              },
              failureCallback: () => {
                //alert('failed');
              },
            });
          } else {
            this.setState({
              label:
                "Either Email or Password is incorrect. Please try again.",
            });
          }
        }}
      >
        Login
      </Button>
      <div className="mt-15" style={{ textAlign: 'center' }}>
        New?
        <Link
          to={{ pathname: "/auth/register", search: window.location.search }}
          style={{ marginLeft: '5px', textDecoration: 'underline', font: 'normal normal bold 18px/20px tablet-gothic', color: '#BA1F00' }}>
          Register
        </Link>
      </div>
      <div style={{ marginTop: '20px', textAlign: 'center', font: 'normal normal normal 14px/26px tablet-gothic', letterSpacing: '0.28px', color: '#9B9B9B' }}> Copyright 2019-21; Illumine. All rights reserved.</div>
    </div>
  }
}

Loginform.propTypes = {
  classes: PropTypes.object.isRequired,
  loginAttempt: PropTypes.func,
  authMsg: PropTypes.string,
  token: PropTypes.string
};

export default withStyles(styles)(Loginform);
