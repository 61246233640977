import React from 'react';
import { Button } from "@material-ui/core"
import { ChevronLeft } from "@material-ui/icons"
import FP_Icon from './assets/Flashpoint_Icon.svg'
import CloseDialog from "./CloseDialog"

export default ({ history, showFlashpoint, showDescription, unit, setShowDescription, alternateResponse, submit, title, questionsBack }) => {
    return (
        <div className='fp-unit-title textbox-scrollable-darkgray' >
            <div className="d-flex" style={{ padding: "2%", alignItems: 'center', justifyContent: 'space-between' }}>
                <Button className="text-decoration-none" classes={{
                    label: 'small-text'
                }} style={{ color: 'black', padding: 0 }} onClick={() => {
                    // if (submit) {
                    //     // setSubmit(false)
                    //     window.location.search.replace("&submit=true", "")
                    // }
                    // else if (alternateResponse) {
                    //     // setAlternateResponse(false)
                    //     window.location.search = window.location.search.replace("&response=true", "")
                    // }

                    // else if (showFlashpoint && showDescription != -1) {
                    //     setShowDescription(-1)
                    // }
                    // else
                    //     window.location.search = window.location.search.replace("?flashpoint=true", "")
                    if (showFlashpoint && showDescription !== -1) {
                        setShowDescription(-1)
                    }
                    else if (questionsBack) {
                        questionsBack();
                    }
                    else history.go(-1);
                }}><ChevronLeft /> Back</Button>
                <div className='d-flex' style={{ width: '3.5rem', justifyContent: 'space-around' }}>
                    <div className='fp-stepper-dots' style={{ opacity: !alternateResponse && showFlashpoint ? 1 : 0.5 }}></div>
                    <div className='fp-stepper-dots' style={{ opacity: alternateResponse ? 1 : 0.5 }}></div>
                    <div className='fp-stepper-dots' style={{ opacity: submit ? 1 : 0.5 }}></div>
                </div>
                {/* <Button className="text-decoration-none" classes={{
                label: 'small-text'
            }} style={{ color: 'black', padding: 0 }} onClick={() => { unit?.isCompleted ? window.location.href = "/flashpoints" : setClose(true) }}> CLOSE X</Button>
            {close && showCloseDialog()} */}
                <CloseDialog unitCompleted={unit?.isCompleted} />

            </div>
            <div className='font-weight-bold' style={{
                display: 'flex',
                padding: '0 2%',
                alignItems: 'center'
            }}>
                <img src={unit?.icon ? unit?.icon : FP_Icon} className='fp-icon' alt='fp-icon' />
                <div className='large-text' style={{ lineHeight: '1.2' }}>
                    {title ? title : unit?.title}
                </div>
            </div>
        </div>
    )
}
