import React from "react"
import { Button, FilledInput, FormControl, IconButton, InputAdornment, InputLabel } from "@material-ui/core"
import { Visibility, VisibilityOff } from "@material-ui/icons"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { resetPasswordUpdate } from "./redux_actions"

export default ({ }) => {
    const [showPassword, setShowPassword] = useState(false)
    const [password, setPassword] = useState(null)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)
    const [confirmPassword, setConfirmPassword] = useState(null)
    const [error, setError] = useState(null)
    const dispatch = useDispatch()
    const user_uid = useSelector(state => state.auth.reset_user_uid)
    const [success, setSuccess] = useState(false)

    useEffect(() => {
        dispatch({ type: "PASSWORD_UPDATE_SUCCESS", payload: '' })
    }, [])

    let getQueryVariable = variable => {
        var query = window.location.search.substring(1);
        // console.log(query)//"app=article&act=news_content&aid=160990"
        var vars = query.split("&");
        // console.log(vars) //[ 'app=article', 'act=news_content', 'aid=160990' ]
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split("=");
            // console.log(pair)//[ 'app', 'article' ][ 'act', 'news_content' ][ 'aid', '160990' ] 
            if (pair[0] == variable) { return pair[1]; }
        }
        return (false);
    }

    let handleValidation = (pwd, pwdc) => {
        setError(null)

        //Password
        if (!pwd) {
            setError("Password can not be empty")
            return
        }
        //PasswordConfirm
        if (!pwdc) {
            setError("Confirm Password can not be empty")
            return
        }

        if (pwd != pwdc) {
            setError("Password does not match.")
            return
        }
        return true;
    }

    return <div style={{ margin: '60px 30px' }}>
        <div style={{ color: '#FFBC36', font: 'normal normal 800 28px/38px tablet-gothic' }}>myApp</div>
        <div className="mb-15" style={{ font: 'normal normal 900 32px/34px Source Serif Pro', letterSpacing: '0.32px' }}>Starmaker Associate Academy</div>
        <div className="ap-login-field-container mb-15">
            <div style={{ textAlign: "center" }}>
                {error || user_uid?.message ? <span style={{ color: "red", paddingLeft: "10px " }}>{error || user_uid?.message}</span> : <></>}
                {success ? <span style={{ color: "green", paddingLeft: "10px", textAlign: "center" }}>New Password is set successfully. Please sign in with new credentials.</span> : ""}
            </div>
            <div className="ap-login-field-container mt-15">
                <FormControl fullWidth className={"input-cntr"} variant="filled">
                    <InputLabel htmlFor="adornment-password">New Password</InputLabel>
                    <FilledInput
                        id="user-password"
                        label="New Password"
                        autoComplete="off"
                        disableUnderline
                        type={showPassword ? "text" : "password"}
                        inputProps={{ style: { backgroundColor: "#FFFFFF" } }}
                        value={password}
                        onChange={(event) => setPassword(event.target.value)}
                        helperText
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="Toggle password visibility"
                                    onClick={() => setShowPassword(password => !password)}
                                    onMouseDown={() => setShowPassword(password => !password)}
                                >
                                    {showPassword ? (
                                        <VisibilityOff />
                                    ) : (
                                        <Visibility />
                                    )}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </FormControl>
            </div>

            <div className="ap-login-field-container mt-15" >
                <FormControl fullWidth className={"input-cntr"} variant="filled">
                    <InputLabel htmlFor="adornment-password">Confirm Password</InputLabel>
                    <FilledInput
                        id="user-password"
                        label="Confirm Password"
                        autoComplete="off"
                        disableUnderline
                        type={showConfirmPassword ? "text" : "password"}
                        inputProps={{ style: { backgroundColor: "#FFFFFF" } }}
                        value={confirmPassword}
                        onChange={(event) => setConfirmPassword(event.target.value)}
                        helperText
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="Toggle password visibility"
                                    onClick={() => setShowConfirmPassword(password => !password)}
                                    onMouseDown={() => setShowConfirmPassword(password => !password)}
                                >
                                    {showConfirmPassword ? (
                                        <VisibilityOff />
                                    ) : (
                                        <Visibility />
                                    )}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </FormControl>
            </div>

            <Button
                fullWidth
                className="login-button mt-15"
                onClick={() => {
                    let token = getQueryVariable('token');
                    let chkVal = handleValidation(password, confirmPassword);
                    // console.log(chkVal)
                    if (chkVal) {
                        dispatch(resetPasswordUpdate({
                            password,
                            token,
                            successCallBack: (res) => {
                                setSuccess(true)
                            },
                            failureCallback: (err) => {
                                setError(err);
                            }
                        }))
                    }
                }}
            >
                Reset Password
            </Button>
            <div className="text-align-center mt-15">
                <Link className="text-decoration-none margin-auto color-brown fw-bold" to={{ pathname: "/auth/login" }}>Go to Login</Link>
            </div>
        </div>
    </div >
}