import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Avatar, Button, CircularProgress, Dialog, DialogContent, DialogTitle, FormControl, Icon, InputLabel, Menu, MenuItem, Select, TextField } from "@material-ui/core"
import { ExpandMore, AddCircle, ThumbUp as ThumbUpIcon, ThumbUpOutlined as ThumbUpOutlinedIcon, Cancel, Close, DeleteForever } from '@material-ui/icons';
import { makeStyles } from "@material-ui/styles";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import store from "../../../Redux_Store";
import { addResponse, deleteFpResponse, getBestPractice, voteResponse } from "../redux_actions";
import Header from "./header";
import StarIcon from '@material-ui/icons/Star';

import TextareaAutosize from '@material-ui/core/TextareaAutosize';

const useStyles = makeStyles({
    outlinedLabel: {
        transform: 'translate(14px, 10px) scale(1)'
    },
    outlinedInput: {
        padding: 10,
    }
});


export default ({ app, auth, history, next }) => {

    const { user_token, user_uid, userAccount } = auth
    let unitId = app?.fpSelectedUnit?._id;

    const [open, setOpen] = useState(false);
    const [menu, setMenu] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [textResponse, setTextResponse] = useState('');
    const [someResponse, setSomeResponse] = useState([]);
    const [editResponseValue, setEditResponseValue] = useState('');
    const [editResponseIndex, setEditResponseIndex] = useState('');
    const [voteLoading, setVoteLoading] = useState(false);
    const [pageNum, setPageNum] = useState(2);
    const moderator = userAccount?.role?.find(ele => ele.rolename === "Flashpoint Response Moderator");

    const classes = useStyles();

    const upVote = (flashpointId, responseId, index) => () => {
        if (!voteLoading) {
            setVoteLoading(true);
            // voteResponse(user_token, flashpointId, responseId, user_uid, someResponse[index]?.isVoted ? -1 : 1).then((res) => {
            voteResponse(user_token, flashpointId, responseId, user_uid, app.alternateResponse[index]?.isVoted ? -1 : 1).then((res) => {
                // if (res?.code) {
                //     alert(res?.message)
                //     return
                // }
                // let temp = [...app?.alternateResponse];
                // temp[index] = res
                // console.log(temp);
                // store.dispatch({ type: "ALTERNATE_RESPONSE", payload: temp })
                // setResponse(temp)


                // let temp = [...someResponse];
                // temp[index] = res
                // setSomeResponse(temp);
                let temp = [...app?.alternateResponse];
                temp[index] = res;
                store.dispatch({ type: "ALTERNATE_RESPONSE", payload: temp });
                setVoteLoading(false);
            })
        }

        setEditResponseIndex('');
    }

    const getMenu = (menu) => {
        switch (menu) {
            case "Text":
                return <Dialog open={openDialog} onClose={() => setOpenDialog(false)} classes={{ paper: 'overFlowYVisible' }}>
                    <DialogTitle>{userAccount?.fullname}</DialogTitle>
                    <DialogContent>
                        <TextField
                            fullWidth
                            value={textResponse}
                            multiline
                            rows={3}
                            rowsMax={3}
                            variant='outlined'
                            onChange={(event) => setTextResponse(event.target.value)}
                            style={{ backgroundColor: "white" }}
                            placeholder="Click to type your solution!" />
                        <Accordion style={{
                            // backgroundColor: "#FEE8AA",
                            width: "fit-content", boxShadow: "none",
                            height: 'fit-content', minHeight: 'fit-content'
                        }}>
                            <AccordionSummary style={{
                                // backgroundColor: "#FEE8AA",
                                width: "fit-content", boxShadow: "none",
                                height: 'fit-content', minHeight: 'fit-content',
                                lineHeight: 0
                            }} expandIcon={<ExpandMore />}>
                                You can share
                            </AccordionSummary>
                            <AccordionDetails>
                                <ul style={{ marginLeft: '10%' }}>
                                    <li>A customer story</li>
                                    <li>Your personal hacks & tricks</li>
                                    <li>Learning from seniors/dealer</li>
                                    and more..
                                </ul>
                            </AccordionDetails>
                        </Accordion>
                        <Button style={{ color: "#BA1F00" }} onClick={() => {
                            if (textResponse.length)
                                addResponse(user_token, unitId, userAccount.id, textResponse).then(res => {
                                    if (!res?.code) {
                                        // let temp = [res.data];
                                        // temp.push(...app?.alternateResponse)
                                        // setResponse(temp);
                                        store.dispatch({ type: "ADD_ALTERNATE_RESPONSE", payload: res.data })

                                        // temp = [res];
                                        // temp.push(...someResponse);
                                        // setSomeResponse(temp);

                                        setTextResponse('');
                                        setOpenDialog(false);

                                    }
                                })
                            else
                                alert('Please fill the textbox')
                        }}>Add</Button>
                    </DialogContent>
                    <Close className='fp-alternate-solution-close-button' onClick={() => setOpenDialog(false)} />
                </Dialog >
        }
    }

    const deleteResponse = async (index) => {
        if (window.confirm('Are you sure?')) {
            await deleteFpResponse(user_token, app?.alternateResponse[index]?._id).then(res => {
                if (!res?.code)
                    alert('Deleted Succesfully')
            })
            let temp = [...app?.alternateResponse];
            temp.splice(index, 1);
            store.dispatch({ type: "ALTERNATE_RESPONSE", payload: temp })
        }
    }


    const loadMoreResponse = () => {
        store.dispatch({ type: "LOAD_ALTERNATE_RESPONSE_LOADING" })
        getBestPractice(user_token, unitId, "local_area", user_uid, pageNum).then(async res => {
            await store.dispatch({ type: "LOAD_ALTERNATE_RESPONSE", payload: res.data })
            let temp = pageNum;
            setPageNum(++temp);
        })
    }

    const editResponse = async (index) => {
        if (window.confirm('Are you sure?')) {
            console.log(editResponseValue)
            let temp = [...app?.alternateResponse];
            temp[index]['textResponse'] = editResponseValue;
            store.dispatch({ type: "ALTERNATE_RESPONSE", payload: temp })
            alert('Edited Succesfully');
        }
        setEditResponseIndex('');
    }


    return <div className='w-100' style={{ backgroundColor: app?.fpSelectedUnit?.color }}>
        <Header history={history} showFlashpoint={false} showDescription={false} setShowDescription={false} unit={app?.fpSelectedUnit} alternateResponse={true} submit={false} />
        <div style={{ padding: "1%" }}>
            {app?.alternateResponse?.length === 0 ?
                <div className='flex-center text-center' style={{ backgroundColor: 'white', height: '65%', borderRadius: '26px', padding: '10%' }}>
                    {app?.fpSelectedUnit.numResponses === 0 && <div style={{ color: '#989595', width: '65%' }}>There are no responses yet. Grab the opportunity to be the first one to add a solution!</div>}
                </div> : <div className='fp-points-description overFlowYAuto textbox-scrollable-darkgray' style={{ padding: '7% 3%' }}>
                    {<>
                        <div className=' font-weight-bold'>See solutions from other associates</div>

                        <div className="alternative-responses">
                            {app?.alternateResponse?.map((res, index) => {
                                return <div key={'response' + index} className="alternative-response">
                                    {moderator && <DeleteForever className='alternate-response-delete-icon' onClick={() => deleteResponse(index)} />}
                                    <Avatar className='fp-alternate-avatar'>{res?.user?.fullname?.split(" ").map((n => n[0]))}</Avatar>
                                    <div className="response-body" style={{ width: moderator ? '75%' : 'unset' }}>
                                        <div className="sender" style={{ fontSize: '0.9rem' }}>
                                            {res?.user?.fullname}
                                            {moderator && <StarIcon />}
                                        </div>

                                        <div style={{ margin: '4% 0%' }}>
                                            {moderator ? <TextareaAutosize style={{ width: '100%', resize: 'none' }} onClick={() => { setEditResponseValue(res?.textResponse); setEditResponseIndex(index) }} value={editResponseIndex === index ? editResponseValue : res?.textResponse} onChange={(event) => setEditResponseValue(event.target.value)} onBlur={() => editResponse(index)} /> : <div className='small-text_2' style={{ lineHeight: '1.2' }}>{res?.textResponse}</div>}
                                            {res?.voiceResponse ? <audio controls style={{ marginTop: "2%" }}>
                                                <source>{res?.audioReponse}</source>
                                            </audio> : ""}
                                            {res?.imgResponse ? <img src={res?.imgResponse}></img> : ""}
                                        </div>
                                        <div className='d-flex small-text_2' style={{ color: 'darkgray' }}>
                                            {(res?.userId?.id !== userAccount?.id) ? (res?.isVoted ? <ThumbUpIcon onClick={upVote(unitId, res._id, index)} /> : <ThumbUpOutlinedIcon onClick={upVote(unitId, res._id, index)} />) : null}
                                            &nbsp;{res?.vote} &nbsp; upvote
                                        </div>
                                    </div>
                                </div>
                            })}
                        </div>
                    </>
                    }
                </div>
            }
        </div>

        {
            app?.fpSelectedUnit?.numResponses > app?.alternateResponse?.length && <div className='w-100 text-center'>
                <Button className='alternate-response-load-more-button' onClick={loadMoreResponse}>
                    {app?.fpAlternateResponseLoading ? <div className='text-center'><CircularProgress /></div> : 'Load more'}
                </Button>
            </div>
        }
        <Button style={{ backgroundColor: '#FFF1C9', margin: '3% 0' }} className='fp-alternative-response-button' onClick={() => { setMenu("Text"); setOpenDialog(true); }}>
            Add your solution &nbsp; <AddCircle fontSize='large' style={{ color: "#BA1F00" }} />
        </Button>
        {getMenu(menu)}
        <Link className='text-decoration-none' to={{
            pathname: `/flashpoints/unit/${unitId}/submit`, state: {
                title: 'Congratulations! You have earned'
            }
        }}>
            <Button className='fp-alternative-response-button' style={{ backgroundColor: 'black', color: 'white' }} onClick={next}>Collect your points</Button>
        </Link>
        <Menu open={open} style={{ position: 'absolute' }} onClose={() => setOpen(false)}>
            <MenuItem onClick={() => { setMenu("Text"); setOpenDialog(true); setOpen(false) }}> Text</MenuItem>
        </Menu>
    </div>
}