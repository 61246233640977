import React, { useEffect, useState } from 'react'
import { Button, RadioGroup, FormControlLabel, Radio, CircularProgress, Accordion as MuiAccordion, AccordionSummary as MuiAccordionSummary, AccordionDetails as MuiAccordionDetails, withStyles, AccordionActions as MuiAccordionActions } from '@material-ui/core';
// import { NavigateNext, NavigateBefore } from '@material-ui/icons';
import { getQuizForModuleId, getReview, getTestResult, getTestSubmission, logUserResponse } from '../../containers/app/redux_actions';
import { useSelector } from 'react-redux';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@material-ui/core/styles';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import Interaction from './Interaction';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import createDOMPurify from "dompurify";

const DOMPurify = createDOMPurify(window);

const Accordion = withStyles({
    root: {
        marginTop: "10px"
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        display: "flex",
        backgroundColor: "#f2f2f2",
        padding: "0"
    },
    content: {
        display: "block",
        marginTop: "0",
        marginBottom: "0",
        paddingTop: "12px",
    },
    expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
}))(MuiAccordionDetails);

const AccordionActions = withStyles((theme) => ({
    root: {
        backgroundColor: "#e9e9e9"
    },
}))(MuiAccordionActions);

export default () => {
    let params = {}
    for (let param of window.location.search.replace("?", "").split("&&")) {
        let _param = param.split("=")
        params[_param[0]] = _param[1]
    }

    const { auth, app } = useSelector((state) => state)
    const { user_token, user_uid } = auth
    const [quizData, setData] = useState({})
    const [activeStep, setActiveStep] = React.useState(0);
    const theme = useTheme();
    const [responses, changeResponse] = useState({})
    const { moduleid } = useParams()
    const session = app.attendedSessions[moduleid]
    const [result, setResult] = useState(null)
    // const [review, takeReview] = useState(params['review'] || false)
    const [submit, setSubmit] = useState(false)
    const [quizId, setQuizId] = useState(null)
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
        getQuizForModuleId({ user_token, moduleid }).then((payload) => {
            setData(payload);
            setQuizId(payload.id)
            setLoading(false)
        })
    }, [])

    useEffect(() => {
        setLoading(true)
        if (quizId && session) {
            getTestResult({ token: user_token, testid: quizId, studentid: user_uid, sessionId: session.sessionId })
                .then((result) => { return result.code ? null : setResult(result) })
            setLoading(false)
            // getReview({
            //     user_token, studentId: user_uid, moduleId: moduleid, successCallBack: (result) => {
            //         let question_list = quizData.questions.map((que) => que._id)
            //         for (let ri in result) {
            //             let r = result[ri]
            //             let que_index = question_list.indexOf(Object.keys(r)[0])
            //             setData(old => {
            //                 return { ...old, questions: { ...old.questions, [que_index]: { ...old.questions[que_index], ...Object.values(result[ri])[0] } } }
            //                 // return { ...quizData, questions: { ...quizData.questions, [que_index]: { ...quizData.questions[que_index], ...Object.values(result[ri])[0] } } }
            //             })

            //         }
            //     }
            // })
            getTestSubmission({ token: user_token, testid: quizId, studentid: user_uid }).then((result) => {
                changeResponse(result)
            })
        }
    }, [quizId, submit])

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    const submitResponse = () => {
        if (responses && quizData?.questions.length === Object.keys(responses).length) {
            setLoading(true)
            logUserResponse({ token: user_token, testid: quizId, studentid: user_uid, answers: responses, sessionId: session.sessionId })
                .then(() => { setSubmit(true); setLoading(false) })
            Interaction.assessmentDone(session.sessionId, session.coachId, auth.userAccount.id)
        }
        else
            setError("One or more questions are not answered.")
    }

    const scoreText = (score) => {
        if (score >= 17)
            return <>
                <div className="outer circle ml-auto mr-auto mt-40px mb-40px" style={{ borderColor: "#1bac00" }}>
                    <div className="score circle inner" style={{ backgroundColor: "#1bac00", borderColor: "#1bac00", color: "white" }}>
                        <div>+{result?.score}</div>
                        <div>/20</div></div>
                </div>
                <div className="assessment-text forestgreen">👏 Outstanding!</div>
                <div className="points">You have added {result?.score} points.</div>
            </>
        else if (score >= 13)
            return <>
                <div className="outer circle ml-auto mr-auto mt-40px mb-40px" style={{ borderColor: "#67c507" }}>
                    <div className="score circle inner" style={{ backgroundColor: "#67c507", borderColor: "#67c507", color: "white" }}>
                        <div>+{result?.score}</div>
                        <div>/20</div></div>
                </div>
                <div className="assessment-text lawngreen">Congratulations!</div>
                <div className="points">You have added {result?.score} points.</div>
            </>
        else if (score >= 8)
            return <>
                <div className="outer circle ml-auto mr-auto mt-40px mb-40px" style={{ borderColor: "#bde100" }}>
                    <div className="score circle inner" style={{ backgroundColor: "#bde100", borderColor: "#bde100", color: "white" }}>
                        <div>+{result?.score}</div>
                        <div>/20</div></div>
                </div>
                <div className="assessment-text greenyellow">Almost there..</div>
                <div className="points">You have added {result?.score} points.</div>
            </>
        else if (score >= 0)
            return <>
                <div className="outer circle ml-auto mr-auto mt-40px mb-40px" style={{ borderColor: "#ff8181" }}>
                    <div className="score circle inner" style={{ borderColor: "#ff8181", color: "#535353" }}>
                        <div>+{result?.score}</div>
                        <div>/20</div></div>
                </div>
                <div className="assessment-text red">SORRY!</div>
                <div className="points">You have added {result?.score} points only.</div>
            </>
    }

    const handleChange = ({ qId, oId }) => {
        changeResponse({ ...responses, [qId]: oId })
    };

    if (loading)
        return <CircularProgress />

    if (!session)
        return "You cannot access this assessment since you have not marked your attendance earlier."

    // if (review)
    //     return <div style={{ margin: "10px" }}>
    //         <span className="assessment-heading">Review your answers</span>
    //         <span className="ml-auto f-right pointer" onClick={() => takeReview(false)}>X</span>
    //         {quizData?.questions && Object.values(quizData?.questions).map((que, index) => <Accordion>
    //             <AccordionSummary
    //                 // expandIcon={<ExpandMoreIcon />}
    //                 style={{ display: "block" }}
    //             >
    //                 <div className="indian-red fw-bold ml-20 mr-20">{parseInt(index) + 1} of {Object.keys(quizData?.questions).length}</div>
    //                 <div className="ml-20 mt-15px mr-20">{que.question}</div>
    //                 <AccordionActions className="mt-15px">
    //                     {que.score > 0 ? <div className="mr-auto green fw-bold">Correct</div> : <div className="indian-red fw-bold mr-auto">Incorrect</div>}
    //                     <div><ExpandMoreIcon /></div>
    //                 </AccordionActions>
    //             </AccordionSummary>
    //             <AccordionDetails>
    //                 <RadioGroup className="answer" value={que.answer || ""} name={que._id}>
    //                     {que.answers.map((ans) =>
    //                         <FormControlLabel label={ans.option} control={<Radio />} label={ans.option} value={ans.option} disabled={review} />)}
    //                 </RadioGroup>
    //             </AccordionDetails>
    //         </Accordion>
    //         )
    //         }
    //     </div>
    // else 
    if (result)
        return <div className="assessment-result">
            <div className="heading">Assessment Score</div>
            {scoreText(result.score)}
            {/* <Button className="sandybrown text-upper border br-25px border-sandybrow fw-bold pl-30px pr-30px pt-10px pb-10px mt-15px" onClick={() => takeReview(true)}> <AssignmentTurnedInIcon /> &nbsp; Review your answers</Button> */}
            <Link className="special-button" style={{ color: 'black', width: '50%', margin: '20px auto 0px', display: 'block', textDecoration: 'none' }} to={`/classroom/rating/${moduleid}`}>Next</Link>
        </div >

    else
        return (
            <div className="assessment">
                <span className="assessment-heading">Assessment</span>
                <SwipeableViews
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={activeStep}
                    onChangeIndex={handleStepChange}
                    enableMouseEvents
                >
                    <div>
                        <div className="question-box">About the Test</div>
                        {quizData?.testInstructions?.map((instruction) => <div className="answer">{instruction}</div>)}

                        <Button className="special-button w-100" onClick={handleNext}> Next
                        </Button>
                    </div>
                    {quizData?.questions?.map((que, index) => (

                        <div key={index}>
                            {Math.abs(activeStep - index) <= 1 ? <>
                                <div className="question-box">
                                    <span>{`${index + 1} of ${quizData?.questions?.length}`}</span>
                                    <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(que.question) }} />
                                </div>
                                <RadioGroup name={que._id} value={responses[que._id] || null} onChange={(event) => handleChange({ qId: que._id, oId: event.target.value })} className="answer">
                                    {que.answers.map((ans) =>
                                        <FormControlLabel label={ans.option} control={<Radio />} label={ans.option} value={ans.option} />)}
                                </RadioGroup>
                                {error && (index == quizData?.questions.length - 1) ? <div className="error">{error}</div> : ""}
                                <Button className="special-button" style={{ width: '100px' }} onClick={handleBack}>
                                    Previous
                                </Button>
                                {/* <Button className="special-button" style={{ width: '100px', float: 'right' }} onClick={index !== quizData?.questions?.length - 1 ? handleNext : (review ? () => takeReview(false) : submitResponse)}> */}
                                <Button className="special-button" style={{ width: '100px', float: 'right' }} onClick={index !== quizData?.questions?.length - 1 ? handleNext : submitResponse}>
                                    {index == quizData?.questions?.length - 1 ? "Submit" : "Next"}
                                    {/* {index == quizData?.questions?.length - 1 ? (review ? "Show Report" : "Submit") : "Next"} */}
                                </Button>
                            </> : null}
                        </div>
                    ))}
                </SwipeableViews>
                {/* <div className="exit">
                    <Link><RemoveCircleOutlineIcon />exit assessment</Link>
                </div> */}
            </div >
        )
}