const INITIAL_STATE = {
  authInProgress: false,
  resetPasswordInProgress: false,
  passwordLinkSent: false,
  userRegistrationInProgress: false,
  anyAuthRequestInProgress: false,
  isLoggedIn: false,
  user_token: "",
  user_uid: "",
  loginErrorMsg: "",
  forgotPasswordErrorMsg: "",
  registrationErrorMsg: "",
  availableModule: [],
  userAccount: null,
  accesscodeData: {}, //Access Code Details
  accesscodeDataLength: 0,
  reset_user_uid: ''
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "Navigation/NAVIGATE":
      return state;
    case "LOGIN_ATTEMPT": //login progress
      return { ...state, msg: "", authInProgress: true };
    case "LOGIN_SUCCEEDED": //login sucess
      return {
        ...state,
        ...INITIAL_STATE,
        isLoggedIn: true,
        user_token: action.payload.tokens.access.token,
        userAccount: action.payload.user
      };
    case "UPDATE_USER_ID":
      return {
        ...state,
        user_uid: action.payload
      }
    case "REGISTER_SUCCEEDED": //register sucess
      return {
        ...state,
        ...INITIAL_STATE,
      };
    case "LOGIN_FAILED": //login failed
      return { ...state, loginErrorMsg: action.payload, authInProgress: false };
    case "LOGOUT_ATTEMPT": //logout progress
      console.log("attempted");
      return {
        ...state, ...INITIAL_STATE, engList: {},
        checkOTPError: ''
      };
    case "LOGOUT_SUCCEEDED": //logout sucess
      return { ...state, isLoggedIn: false, user_uid: "", user_token_expiry: "", uset_token: "" };
    case "LOGOUT_FAILED": //logout failed
      return { ...state, loginErrorMsg: "Logout Failed" };
    case "SET_LOGIN_ERROR_MSG":
      return { ...state, loginErrorMsg: action.payload };
    case "REGISTER_ATTEMPT":
      return { ...state, loginErrorMsg: "", authInProgress: true };
    case "MYPROFILE_ATTEMPT":
      return { ...state, loginErrorMsg: "", authInProgress: true };
    case "PASSWORD_RESET_IN_PROGRESS":
      return {
        ...state,
        forgotPasswordErrorMsg: "",
        resetPasswordInProgress: action.payload
      };
    case "PASSWORD_UPDATE_SUCCESS":
      return { ...state, reset_user_uid: action.payload };
    case "SET_RESET_PASSWORD_ERROR_MSG":
      return {
        ...state,
        forgotPasswordErrorMsg: action.payload,
        resetPasswordInProgress: false
      };
    case "RESET_PASSWORD_LINK_SENT":
      return {
        ...state,
        resetPasswordInProgress: false,
        passwordLinkSent: true
      };
    case "UPDATE_TOKEN":
      return { ...state, user_token: action.payload };
    case "UPDATE_USER_UID":
      return { ...state, user_uid: action.payload };
    case "RESET_LOGIN_PAGE":
      return { ...state, loginErrorMsg: "" };
    case "RESET_FORGOT_PASSWORD_PAGE":
      return { ...state, ...INITIAL_STATE };
    case "RESET_REGISTER_NEW_USER_PAGE":
      return { ...state, ...INITIAL_STATE };
    case "REGISTER_ATTEMPT_FAILED":
      return { ...state, loginErrorMsg: action.payload }
    case "GETACCESSTOKENDETAILS_ATTEMPT":
      return { ...state, isLoadingData: true };
    case "GETACCESSTOKENDETAILS_SUCCEEDED":
      return {
        ...state,
        accesscodeData: action.data,
        accesscodeDataLength: action.data.length
      };
    default:
      return state;
  }
};
