import React, { Component } from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import Auth from "./containers/auth";
import Public from "./containers/public";
import App from "./containers/app";

import "./Main.css";
import "./Typography.css";



let Main = class Main extends Component {
  render() {
    return (
      <Switch>
        <Route path="/auth" component={Auth} />
        {this.props.auth.isLoggedIn ?
          <Route path="/" component={App} /> :
          <Route path="/" component={Public} />
        }
      </Switch>
    );
  }
};

const mapStateToProps = state => ({ auth: state.auth });
export default withRouter(connect(mapStateToProps, null)(Main));