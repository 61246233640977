/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Redirect } from "react-router";

import Registration from "../../components/RegistrationForm";
import { registerAttempt, setErrMsg, resetLoginPage, getAccessTokenDetails, loginAttempt, subscibe } from "./redux_actions";
import { Grid } from "@material-ui/core";

let Register = class Register extends Component {
  constructor(props) {
    super(props);

    this.params = {}
    for (let param of window.location.search.replace("?", "").split("&&")) {
      let _param = param.split("=")
      this.params[_param[0]] = _param[1]
    }

    this.state = {
      email: "",
      password: "",
      showPassword: false,
      firstname: "",
      lastname: "",
      name: "",
      isDesktop: false,
    };
  }

  componentDidMount() {
    //reset all the values in the state;
    this.props.resetLoginPage();
  }

  render() {
    //this.props.requestPermissionToNotify();
    const { classes } = this.props;

    if (this.props.auth.isLoggedIn) {
      if ("otp" in this.params)
        return <Redirect
          to={{ pathname: `/classroom`, search: window.location.search }}
        />
      else
        return <Redirect
          to={{
            pathname: "/",
            search: window.location.search
          }}
        />
    } else {
      return <Registration
        registerAttempt={this.props.registerAttempt}
        authMsg={this.props.auth.loginErrorMsg}
        loginAttempt={this.props.loginAttempt}
        subscibe={this.props.subscibe}
        accesscode={this.params["accesscode"] || ""}
        params={this.params}
      />
    }
  }
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      registerAttempt,
      setErrMsg,
      //requestPermissionToNotify,
      resetLoginPage,
      getAccessTokenDetails,
      registerAttempt,
      loginAttempt,
      subscibe
    },
    dispatch
  );
}

export default connect(mapStateToProps, matchDispatchToProps)(Register);
